import OnboardingContainer from 'presentation/features/onboarding/container';
import useAssistant from '../../hooks/useAssistant';
import AssistantCommonLayout from '../../layouts/common';
import { EAssistantScenario } from '../../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const scenarioType = EAssistantScenario.Onboarding;
export const AssistantScenarioOnboardingContainer = () => {
  const {
    onFinishScenario
  } = useAssistant();
  return _jsx(AssistantCommonLayout, {
    children: _jsx(OnboardingContainer, {
      onFinish: () => onFinishScenario(scenarioType)
    })
  });
};