import { authProviderIsCorpUserSelector } from 'features/auth/provider/store/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAssistant from '../../hooks/useAssistant';
import { EAssistantScenario } from '../../types';
import { AssistantScenarioAspAcceptContainer } from './container';
const scenarioType = EAssistantScenario.AspAccept;

/**
 * сценарий согласия с АСП
 */
export const AssistantScenarioAspAcceptManager = () => {
  const isCorpUser = useSelector(authProviderIsCorpUserSelector);
  const {
    onActivateScenario
  } = useAssistant();
  useEffect(() => {
    if (isCorpUser === true) {
      onActivateScenario(scenarioType);
    }
  }, [isCorpUser, onActivateScenario]);
  return null;
};
export const config = {
  type: scenarioType,
  required: true,
  interval: 40000,
  manager: AssistantScenarioAspAcceptManager,
  component: AssistantScenarioAspAcceptContainer
};