import { Link } from '@mui/material';
import AppSvgIcon from '../../../components/common/icon';
import Splitter from '../../../components/common/splitter';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const MPLink = _ref => {
  let {
    children,
    startIcon,
    iconSize = 'micro',
    ...props
  } = _ref;
  return _jsxs(Link, {
    ...props,
    children: [startIcon && _jsxs(_Fragment, {
      children: [_jsx(AppSvgIcon, {
        fontSize: iconSize,
        icon: startIcon
      }), _jsx(Splitter, {
        size: 1,
        variant: "vertical"
      })]
    }), children]
  });
};