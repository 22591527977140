import { Typography } from '@mui/material';
import { Wrapper } from './controls';
import Splitter from 'presentation/components/common/splitter';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const StatusText = _ref => {
  let {
    status,
    requisites
  } = _ref;
  return _jsxs(Wrapper, {
    children: [_jsx(Typography, {
      children: status
    }), _jsx(Splitter, {
      variant: "vertical",
      size: 0.5
    }), _jsx(Typography, {
      textAlign: "center",
      color: "textSecondary",
      children: requisites
    })]
  });
};
export default StatusText;