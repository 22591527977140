import useAssistant from 'presentation/features/assistant/hooks/useAssistant';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { useSelector } from 'react-redux';
import { assistantScenarios } from '../data';
import AssistantLayout from '../layouts/assistant';
import { assistantActiveScenarioSelector, assistantIsOpenSelector } from '../store/selectors';
import AssistantScenarioActivator from './activator';
import useAssistantWidget from './useWidget';
import AssistantScenarioRatingContainer from '../scenarios/rating/container';
import AssistantScenarioViewer from './viewer';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const scenarios = assistantScenarios;
const AssistantWidgetContainer = () => {
  const {
    hasFeature
  } = useTechConfig();
  const open = useSelector(assistantIsOpenSelector);
  const activeScenario = useSelector(assistantActiveScenarioSelector);
  const assistant = useAssistant();
  useAssistantWidget({
    scenarios
  });
  if (!hasFeature(EAppFeature.Assistant)) {
    return null;
  }
  return _jsxs(AssistantLayout, {
    open: open,
    onToogle: activeScenario?.required ? undefined : assistant.onToggle,
    onClose: activeScenario?.required ? undefined : assistant.onClose,
    children: [scenarios.map(_ref => {
      let {
        type,
        manager: Manager,
        component: Component
      } = _ref;
      return _jsxs(_Fragment, {
        children: [_jsx(AssistantScenarioActivator, {
          type: type,
          children: _jsx(Manager, {})
        }, type), _jsx(AssistantScenarioViewer, {
          type: type,
          children: _jsx(Component, {})
        }, type)]
      });
    }), !activeScenario && _jsx(AssistantScenarioRatingContainer, {})]
  });
};
export default AssistantWidgetContainer;