import AspBanner from 'presentation/features/offer/asp/components/banner';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OnboardingAspAcceptAdapter = _ref => {
  let {
    onNext
  } = _ref;
  return _jsx(AspBanner, {
    bonusSize: 13500,
    year: 2025,
    deactivateTimestamp: '2024-12-05',
    onAccept: onNext
  });
};