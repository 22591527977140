import SupportIcon from '@mui/icons-material/Support';
import { Badge } from '@mui/material';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AssistantCallButton = _ref => {
  let {
    open,
    warning,
    onClick
  } = _ref;
  return _jsx(Badge, {
    invisible: open || !warning,
    color: "warning",
    variant: "dot",
    children: _jsx(Wrapper, {
      open: open,
      color: 'info',
      size: 'small',
      onClick: onClick,
      children: _jsx(SupportIcon, {
        fontSize: "large"
      })
    })
  });
};
export default AssistantCallButton;