import { Dialog, useMediaQuery } from '@mui/material';
import { CloseIcon } from 'presentation/media/icons';
import { CloseButton, StyledDialogTitle } from './controls';
import { MPDialogBackButton } from './dialogBackButton';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const MPDialog = props => {
  const {
    children,
    fullWidth = true,
    maxWidth = 'md',
    closeButtonVisible = true,
    closeButton,
    backButtonLabel,
    title,
    fullScreenBreakpoint,
    ...others
  } = props;
  const fullScreenBreakpointDefault = others.fullScreen ? 10000 : 0;
  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down(fullScreenBreakpoint ?? fullScreenBreakpointDefault));
  const isBackButtonVisible = closeButtonVisible && others.onClose && fullScreen;
  const isCloseIconVisible = closeButtonVisible && others.onClose && !fullScreen;
  return _jsxs(Dialog, {
    fullWidth: fullWidth,
    maxWidth: maxWidth,
    scroll: downSm ? 'paper' : 'body',
    PaperProps: {
      elevation: 0,
      variant: 'outlined'
    },
    ...others,
    fullScreen: others.fullScreen || fullScreen,
    children: [isBackButtonVisible && _jsx(MPDialogBackButton, {
      label: backButtonLabel,
      onClick: event => others.onClose?.(event, 'escapeKeyDown')
    }), isCloseIconVisible && _jsx(_Fragment, {
      children: closeButton ? closeButton : _jsx(CloseButton, {
        onClick: event => others.onClose?.(event, 'backdropClick'),
        children: _jsx(CloseIcon, {
          fontSize: "large"
        })
      })
    }), title && _jsx(StyledDialogTitle, {
      compact: !!isBackButtonVisible,
      children: title
    }), children]
  });
};