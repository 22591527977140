import { userApi } from 'data/api/user';
export const currentUserIdSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.id ?? null;
};
export const currentUserLocalityIdSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.locality?.id ?? null;
};
export const currentUserBalancesSelector = store => {
  const {
    data: value
  } = userApi.endpoints.getUserBalance.select()(store);
  return value ?? null;
};