import OrderListItemLayout from '../layout';
import { EDateFormat } from 'domain/model/formats';
import { SeeAndSunIcon } from 'presentation/media/icons';
import { utcToLocalTimeZone } from '../../../../../utils/date';
import { toRubCurrency } from '../../../../../utils/currency';
import { getRequisites, getStatusElement, getStatusName } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferOrderAdapter = props => {
  const {
    order,
    orderStatuses,
    onClick
  } = props;
  const date = utcToLocalTimeZone(order.createdAt).format(EDateFormat.Human);
  const requisites = getRequisites(order.number, date);
  const statusName = getStatusName(orderStatuses, order.status);
  const cost = toRubCurrency(order.cost);
  return _jsx(OrderListItemLayout, {
    status: getStatusElement(statusName, order),
    name: order.offer?.name ?? '',
    cost: cost,
    icon: SeeAndSunIcon,
    requisites: requisites,
    onClick: onClick
  });
};
export default BookingOfferOrderAdapter;