import { EOrderByDateType } from 'domain/model/enums';
import { ExpandableText } from 'presentation/components/common/expandableText';
import { useState } from 'react';
import { ServiceSelect } from '../select';
import { ExpandableHTML, Header, Info, List, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ServiceSelector = _ref => {
  let {
    cart,
    onChange,
    ...priceUnit
  } = _ref;
  const {
    name,
    description,
    priceItems
  } = priceUnit;
  const [isExpanded, setIsExpanded] = useState(false);
  const handleChange = priceItemId => {
    return () => {
      onChange({
        priceUnit,
        priceItemId,
        isIncrement: priceUnit.orderByDateType === EOrderByDateType.None
      });
    };
  };
  return _jsxs(Wrapper, {
    children: [_jsxs(Info, {
      children: [name && _jsx(Header, {
        variant: 'h2',
        children: name
      }), description && _jsx(ExpandableText, {
        as: "div",
        expanded: isExpanded,
        onExpand: () => setIsExpanded(!isExpanded),
        children: _jsx(ExpandableHTML, {
          dangerouslySetInnerHTML: {
            __html: description
          }
        })
      })]
    }), priceItems && _jsx(List, {
      children: priceItems.map(item => _jsx(ServiceSelect, {
        ...item,
        // по старой логике способен отображать +/-. Чтобы этого не было, добавлен 0 по-умолчанию
        count: 0,
        onChange: handleChange(item.id)
      }, item.id))
    })]
  });
};