import { parseEnv } from 'utils/env';
export class AppConfigurator {
  static getInstance() {
    if (!AppConfigurator.instance) {
      AppConfigurator.instance = new AppConfigurator();
    }
    return AppConfigurator.instance;
  }
  constructor() {}
  options = (() => AppConfigurator.init())();
  static loadEnv() {
    if (process.env.NODE_ENV === 'test') {
      return {
        appVersion: '',
        apiBase: '',
        apiPath: '',
        apiFilesPath: '',
        apiBonusesPath: '',
        apiAddressPath: '',
        apiWs: '',
        apiWsPath: '',
        apiWsSockjsPath: '',
        wsEnabled: '',
        authServiceIdpHint: '',
        authServiceIdpRzhdPortal: '',
        authServiceClientId: '',
        authServiceRealm: '',
        authServiceUrl: '',
        supportPhones: '',
        supportEmail: '',
        debug: '',
        features: '',
        corpEarningsSystemUrl: '',
        partnerPersonalAccountUrl: '',
        corpLinkProxyUrl: '',
        siteCaption: '',
        pulseUrl: '',
        pulseCaption: '',
        pulseSupportEmail: '',
        sentryEnabled: '',
        sentryDsn: '',
        sentryTags: '',
        sentryProject: '',
        sentryRelease: '',
        sentryEnvironment: '',
        sentryCaptureUserAttributes: '',
        sentryAutoSessionTracking: '',
        sentryDebug: '',
        externalThemes: null
      };
    } else {
      const request = new XMLHttpRequest();
      const staticConfigUrl = '/config/app.env';
      const versionFileUrl = '/version.txt';
      let staticOptions = null;
      request.open('GET', staticConfigUrl, false);
      request.send();
      if (request.status === 200) {
        staticOptions = parseEnv(request.responseText);
        console.log('app static options', staticOptions);
      }
      if (!staticOptions || !Object.keys(staticOptions).length) {
        throw Error(`Not found application static config '${staticConfigUrl}'`);
      }
      let runtimeOptions = {
        appVersion: '',
        externalThemes: null
      };
      request.open('GET', versionFileUrl, false);
      request.send();
      if (request.status === 200) {
        const version = request.responseText.trim();
        runtimeOptions = {
          ...runtimeOptions,
          appVersion: version
        };
        console.log('app version', version);
      }

      /*
      по факту не используется, комментарий ниже к getExternalTheme
      const themeFileUrl = '/themes/theme.json';
      request.open('GET', themeFileUrl, false);
      request.send();
      if (request.status === 200) {
        try {
          const externalThemes: ExternalThemes = JSON.parse(request.responseText.trim().replace(/[\n\r]/g, ''));
          runtimeOptions = { ...runtimeOptions, externalThemes };
        } catch (e) {
          console.error(e);
        }
      }*/

      return {
        ...staticOptions,
        ...runtimeOptions
      };
    }
  }
  setOptions(options) {
    this.options = options;
  }
  static init() {
    const env = AppConfigurator.loadEnv();
    let sentryTags = null;
    if (env.sentryTags) {
      try {
        const parsedSentryTags = JSON.parse(env.sentryTags);
        if (typeof parsedSentryTags === 'object') {
          sentryTags = parsedSentryTags;
        } else {
          console.warn('Sentry tags is not object');
        }
      } catch (e) {
        console.warn('Sentry tags is not json format');
      }
    }
    let sentryCaptureUserAttributes = null;
    if (env.sentryCaptureUserAttributes) {
      try {
        sentryCaptureUserAttributes = env.sentryCaptureUserAttributes.split(',').map(item => item.trim());
      } catch (e) {
        console.warn('Sentry capture user attribute parse error', sentryCaptureUserAttributes);
      }
    }
    return {
      apiBase: {
        url: env.apiBase ?? '',
        path: env.apiPath ?? '',
        filesPath: env.apiFilesPath ?? '',
        bonusesPath: env.apiBonusesPath ?? '',
        addressPath: env.apiAddressPath ?? ''
      },
      apiWs: {
        url: env.apiWs ?? '',
        path: env.apiWsPath ?? '',
        sockjsPath: env.apiWsSockjsPath ?? '',
        enabled: (env.wsEnabled || 'true') === 'true'
      },
      apiAuthService: {
        url: env.authServiceUrl,
        realm: env.authServiceRealm,
        clientId: env.authServiceClientId,
        idpHint: env.authServiceIdpHint ?? '',
        idpRzhdPortal: env.authServiceIdpRzhdPortal ?? ''
      },
      supportInfo: {
        phones: env.supportPhones,
        email: env.supportEmail ?? ''
      },
      debug: env.debug === 'true' || process.env.NODE_ENV === 'development',
      features: env.features?.split(','),
      corpEarningsSystemUrl: env.corpEarningsSystemUrl ?? '',
      partnerPersonalAccountUrl: env.partnerPersonalAccountUrl ?? '',
      corpLinkProxyUrl: env.corpLinkProxyUrl ?? '',
      sentry: {
        enabled: env.sentryEnabled === 'true',
        dsn: env.sentryDsn ?? '',
        debug: env.sentryDebug === 'true',
        tags: sentryTags,
        project: env.sentryProject ?? '',
        release: env.sentryRelease ?? '',
        captureUserAttributes: sentryCaptureUserAttributes ? sentryCaptureUserAttributes : null,
        autoSessionTracking: env.sentryAutoSessionTracking === 'true',
        environment: env.sentryEnvironment ?? 'production'
      },
      siteCaption: env.siteCaption,
      pulse: {
        url: env.pulseUrl ?? null,
        caption: env.pulseCaption ?? null,
        supportEmail: env.pulseSupportEmail ?? null
      },
      bonus: {
        unavailableReason: env.bonusUnavailableReason ?? null
      },
      maps: {
        unavailableReason: env.mapsUnavailableReason ?? null
      },
      externalThemes: env.externalThemes
    };
  }
  getOptions() {
    return this.options;
  }
  getApiAuthService() {
    return this.options.apiAuthService;
  }
  getApiBase() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.path}`;
  }
  getApiFilesPath() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.filesPath}`;
  }
  getApiBonusesPath() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.bonusesPath}`;
  }
  getApiAddressPath() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.addressPath}`;
  }
  getApiWs() {
    return this.options.apiWs;
  }
  getApiWsUrl() {
    return `${this.options?.apiWs?.url}${this.options?.apiBase?.path}${this.options?.apiWs?.path}`;
  }
  getApiWsEnabled() {
    return this.options.apiWs.enabled;
  }
  getSupportInfo() {
    return this.options?.supportInfo;
  }
  getSiteCaption() {
    return this.options?.siteCaption;
  }
  getPulseConfig() {
    return this.options?.pulse;
  }
  getBonusConfig() {
    return this.options?.bonus;
  }
  isDebug() {
    return this.options?.debug ?? false;
  }
  getFeatures() {
    return this.options?.features;
  }
  getCorpEarningsSystemUrl() {
    return this.options?.corpEarningsSystemUrl ?? '';
  }
  getPartnerPersonalAccountUrl() {
    return this.options?.partnerPersonalAccountUrl ?? '';
  }
  getCorpLinkProxyUrl() {
    return this.options?.corpLinkProxyUrl ?? '';
  }
  getMapsConfig() {
    return this.options?.maps ?? {};
  }

  /**
   * @deprecated
   * по факту не используется, это был первый вариант реализации
   * второй вариант - получение из user.org
   * но данный не удаляется, потому что есть сомнения в последнем
   */
  getExternalTheme(names) {
    for (let i = 0; i < names.length; i++) {
      const theme = this.options?.externalThemes?.[names[i]];
      if (theme) {
        return theme;
      }
    }
    return this.options?.externalThemes?.['default'] ?? null;
  }
  hasFeature(feature) {
    return this.options.features?.includes(feature.toString()) ?? false;
  }
}