import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { BalancesWrapper, ContentWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserFinancesLayout = props => {
  const {
    header,
    balances,
    history,
    actions,
    loader,
    children
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsx(DefaultContentWrapper, {
      children: _jsxs(ContentWrapper, {
        children: [_jsx(Typography, {
          variant: "h1",
          children: header
        }), _jsx(Splitter, {
          size: 3
        }), balances && _jsxs(_Fragment, {
          children: [_jsx(BalancesWrapper, {
            children: balances
          }), _jsx(Splitter, {
            size: 4
          })]
        }), history && _jsxs(_Fragment, {
          children: [history, _jsx(Splitter, {
            size: 5
          })]
        }), actions, children, loader]
      })
    })
  });
};