import { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useOnboarding from './useOnboarding';
import OnboardingWrapperLayout from './layouts/wrapper';
import OnboardingStepLayout from './layouts/step';
import 'swiper/css';
import 'swiper/css/effect-creative';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OnboardingContainer = _ref => {
  let {
    onFinish
  } = _ref;
  const {
    steps
  } = useOnboarding();
  const [swiper, setSwiper] = useState(null);
  const onNext = useCallback(() => {
    const index = swiper?.activeIndex ?? 0;
    if (index === steps.length - 1) {
      return onFinish();
    }
    swiper?.slideTo(index + 1);
  }, [swiper, steps.length, onFinish]);
  useEffect(() => {
    if (steps.length === 0) {
      onFinish();
    }
    swiper?.updateAutoHeight();
  }, [onFinish, steps.length, swiper]);
  return _jsx(OnboardingWrapperLayout, {
    children: _jsx(Swiper, {
      slidesPerView: 1,
      allowTouchMove: false,
      initialSlide: 0,
      noSwiping: true,
      loop: false,
      freeMode: true,
      autoHeight: true,
      runCallbacksOnInit: true,
      effect: "creative",
      onSwiper: setSwiper,
      children: steps.map(_ref2 => {
        let {
          type,
          component: Component
        } = _ref2;
        return _jsx(SwiperSlide, {
          children: _jsx(OnboardingStepLayout, {
            children: _jsx(Component, {
              onNext: onNext
            })
          })
        }, type);
      })
    })
  });
};
export default OnboardingContainer;