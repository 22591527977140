import { useMediaQuery } from '@mui/material';
import { ECorpOfferPromotionType, EOfferActivationStatus, EOfferStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferListItemContainer from '../../offer/listItem/container';
import ActivationListItemCodeSidebar from '../components/sidebar/code';
import ActivationListItemFileSidebar from '../components/sidebar/file';
import ActivationListItemLinkSidebar from '../components/sidebar/link';
import ActivationListItemPendingSidebar from '../components/sidebar/pending';
import ActivationListItemRejectedSidebar from '../components/sidebar/rejected';
import ActivationListItemUpcomingSidebar from '../components/sidebar/upcoming';
import ActivationListItemWidgetSidebar from '../components/sidebar/widget';
import { OfferWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItem = props => {
  const {
    activation,
    onOpenHelpDialog,
    onOfferClick,
    onPartnerLinkClick,
    getActivationCodeTypeName,
    onTakeActivation,
    fileDownloading,
    onNeedCopy,
    copied,
    needCopy
  } = props;
  const {
    offer,
    promotion,
    status,
    lastStatusComment
  } = activation;
  const {
    type
  } = promotion;
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const direction = isSmDown ? 'row-reverse' : 'column';
  let listItem = null;
  switch (activation.promotion.type) {
    case ECorpOfferPromotionType.Certificate:
    case ECorpOfferPromotionType.ExternalCertificate:
      listItem = _jsx(OfferListItemContainer, {
        offer: {
          ...offer,
          promotionType: activation.promotion.type,
          price: activation.price ?? 0,
          originalPrice: activation.originalPrice
        }
      }, offer.id);
      break;
    default:
      listItem = _jsx(OfferListItemContainer, {
        offer: {
          ...offer,
          promotionType: activation.promotion.type
        },
        direction: direction
      }, offer.id);
  }
  return _jsx(Wrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      children: [_jsx(MPGrid, {
        item: true,
        zero: 12,
        sm: isSmDown ? 12 : 6,
        children: _jsx(OfferWrapper, {
          direction: direction,
          children: listItem
        })
      }), _jsxs(MPGrid, {
        item: true,
        zero: 12,
        sm: 6,
        children: [(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status === EOfferStatus.Upcoming && _jsx(ActivationListItemUpcomingSidebar, {
          offerName: offer.name,
          offerCode: offer.code,
          startDate: offer.startDate,
          onOfferClick: onOfferClick
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && type === ETradeOfferPromotionType.ReferralLink && _jsx(ActivationListItemLinkSidebar, {
          partnerLink: promotion.publicPromotionValue,
          onPartnerLinkClick: onPartnerLinkClick,
          codeTypeName: getActivationCodeTypeName(type),
          appointmentDate: activation.appointmentDate,
          expireDate: activation.expireDate
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && type === ETradeOfferPromotionType.Widget && _jsx(ActivationListItemWidgetSidebar, {
          codeTypeName: getActivationCodeTypeName(type),
          appointmentDate: activation.appointmentDate,
          expireDate: activation.expireDate,
          activation: activation,
          srcDoc: promotion.publicPromotionValue
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && type === ETradeOfferPromotionType.PublicPromocode && _jsx(ActivationListItemCodeSidebar, {
          appointmentDate: activation.appointmentDate,
          expireDate: activation.expireDate,
          partnerLink: offer.partnerLink,
          code: promotion.publicPromotionValue,
          codeTypeName: getActivationCodeTypeName(type),
          onPartnerLinkClick: onPartnerLinkClick,
          onCopy: onTakeActivation,
          onOpenHelpDialog: () => onOpenHelpDialog(type),
          copied: copied,
          needCopy: needCopy,
          onNeedCopy: onNeedCopy
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && (type === ETradeOfferPromotionType.AccessCode || type === ETradeOfferPromotionType.Promocode) && _jsx(ActivationListItemCodeSidebar, {
          appointmentDate: activation.appointmentDate,
          expireDate: activation.expireDate,
          partnerLink: offer.partnerLink,
          code: promotion.code,
          codeTypeName: getActivationCodeTypeName(type),
          onPartnerLinkClick: onPartnerLinkClick,
          onCopy: onTakeActivation,
          copied: copied,
          needCopy: needCopy,
          onNeedCopy: onNeedCopy,
          onOpenHelpDialog: () => onOpenHelpDialog(type)
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && type === ETradeOfferPromotionType.Voucher && _jsx(ActivationListItemFileSidebar, {
          downloadLabel: promotion.code,
          promotionType: ETradeOfferPromotionType.Voucher,
          partnerLink: offer.partnerLink,
          codeTypeName: getActivationCodeTypeName(type),
          appointmentDate: activation.appointmentDate,
          expireDate: activation.expireDate,
          isDownloading: fileDownloading,
          onPartnerLinkClick: onPartnerLinkClick,
          onDownload: onTakeActivation,
          onOpenHelpDialog: () => onOpenHelpDialog(type)
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && type === ETradeOfferPromotionType.Asp && _jsx(ActivationListItemFileSidebar, {
          downloadLabel: promotion.code,
          promotionType: ETradeOfferPromotionType.Asp,
          codeTypeName: getActivationCodeTypeName(type),
          appointmentDate: activation.appointmentDate,
          expireDate: activation.expireDate,
          isDownloading: fileDownloading,
          onDownload: onTakeActivation,
          onOpenHelpDialog: () => onOpenHelpDialog(type)
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && type === ETradeOfferPromotionType.Digift && _jsx(ActivationListItemFileSidebar, {
          downloadLabel: promotion.code,
          promotionType: ETradeOfferPromotionType.Digift,
          codeTypeName: getActivationCodeTypeName(type),
          appointmentDate: activation.appointmentDate,
          expireDate: activation.expireDate,
          isDownloading: fileDownloading,
          onDownload: onTakeActivation,
          onOpenHelpDialog: () => onOpenHelpDialog(type)
        }), (status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) && offer.status !== EOfferStatus.Upcoming && (type === ECorpOfferPromotionType.ExternalCertificate || type === ECorpOfferPromotionType.Certificate) && _jsx(ActivationListItemFileSidebar, {
          downloadLabel: promotion.code,
          promotionType: type,
          partnerLink: null,
          codeTypeName: getActivationCodeTypeName(type),
          expireDate: activation.expireDate,
          appointmentDate: activation.appointmentDate,
          onPartnerLinkClick: onPartnerLinkClick,
          isDownloading: fileDownloading,
          onDownload: onTakeActivation,
          onOpenHelpDialog: () => onOpenHelpDialog(type)
        }), status === EOfferActivationStatus.Rejected && _jsx(ActivationListItemRejectedSidebar, {
          offerName: offer.name,
          offerCode: offer.code,
          comment: lastStatusComment,
          onOfferClick: onOfferClick
        }), status === EOfferActivationStatus.Pending && _jsx(ActivationListItemPendingSidebar, {
          offerName: offer.name,
          offerCode: offer.code,
          comment: lastStatusComment,
          onOfferClick: onOfferClick
        })]
      })]
    })
  });
};
export default ActivationListItem;