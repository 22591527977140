import { EPartnerType } from 'domain/model/enums';
import AppImage from 'presentation/components/common/images/common';
import { innerPartnerLogoStub } from '../../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskLogo = _ref => {
  let {
    partnerDesk
  } = _ref;
  const {
    image
  } = partnerDesk;
  if (image?.path) {
    return _jsx(AppImage, {
      src: image,
      alt: ""
    });
  }
  if (!image?.path && partnerDesk.partner?.type === EPartnerType.InnerPartner) {
    return _jsx("img", {
      src: innerPartnerLogoStub,
      alt: ""
    });
  }
  return null;
};
export default PartnerDeskLogo;