import { useMediaQuery } from '@mui/material';
import { useMemo, useState } from 'react';
import CarouselGallery from '../../../../../../components/common/carousels/gallery';
import AppImage from '../../../../../../components/common/images/common';
import MediaPreviewDialog from '../../../../product/details/mediaPreviewDialog';
import { offerImageAspectRatio } from '../../../../utils';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import BookingOfferBadge from './badge';
import { CarouselWrapper } from './controls';
import useTechConfig from 'hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { cspOfferTag } from 'features/offer/booking/utils';
import OfferTags from 'features/offer/components/tags';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferCarouselAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  const {
    hasFeature
  } = useTechConfig();
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewInitSlide, setPreviewInitSlide] = useState(0);
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const onMediaClick = () => {
    setPreviewDialogOpen(true);
  };
  const onCloseDialog = () => {
    setPreviewDialogOpen(false);
  };
  const thumbSwiperProps = useMemo(() => ({
    slidesPerView: 4,
    spaceBetween: 10,
    loop: true
  }), []);
  const mainSwiperProps = useMemo(() => ({
    pagination: !isMdUp,
    navigation: false,
    spaceBetween: 10,
    onClick: onMediaClick,
    loop: true
  }), [isMdUp]);
  if (!bookingOffer?.images) {
    return null;
  }
  const {
    images,
    rzdSocialPackage,
    tags
  } = bookingOffer;
  const thumbChildren = images.map((image, i) => _jsx(AppImage, {
    onClick: () => {},
    src: image.path
  }, i));
  const isCspTagDisplay = !!rzdSocialPackage && hasFeature(EAppFeature.Csp);
  const finalTags = [...(tags ?? [])];
  if (isCspTagDisplay) {
    finalTags.push(cspOfferTag);
  }
  return _jsxs(CarouselWrapper, {
    children: [_jsxs(BookingOfferBadge, {
      children: [_jsx(CarouselGallery, {
        swipersGap: 10,
        initSlide: 0,
        thumbSwiperContainerHeight: 102,
        thumbChildren: thumbChildren,
        showThumbs: isMdUp,
        onActiveIndexChange: index => {
          setPreviewInitSlide(index);
        },
        thumbSwiperProps: thumbSwiperProps,
        mainSwiperProps: mainSwiperProps,
        aspectRatio: offerImageAspectRatio,
        children: images.map((image, i) => _jsx(AppImage, {
          onClick: () => {},
          src: image.path
        }, i))
      }), previewDialogOpen && _jsx(MediaPreviewDialog, {
        imageLinks: images,
        initSlide: previewInitSlide,
        open: previewDialogOpen,
        onClose: onCloseDialog
      })]
    }), finalTags && _jsx(OfferTags, {
      tags: finalTags
    })]
  });
};
export default BookingOfferCarouselAdapter;