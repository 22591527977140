import { EOrderShortDiscriminator, EOrderStatus } from '../../../../../../domain/model/enums';
import { Typography } from '@mui/material';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getRequisites = (orderNumber, date) => {
  return `${orderNumber} от ${date}`;
};
export const getStatusName = (orderStatuses, orderStatus) => {
  return orderStatuses.find(status => status.id === orderStatus)?.name;
};
export const getStatusElement = (name, order) => {
  if (!order) {
    return null;
  }
  const isBookingOrder = order.discriminator === EOrderShortDiscriminator.BookingOrderShort;
  if (isBookingOrder) {
    return _jsx(Typography, {
      color: [EOrderStatus.Confirmed].find(i => i === order.status) ? 'success.main' : 'black',
      children: name
    });
  }
  return _jsx(Typography, {
    color: [EOrderStatus.New].find(i => i === order.status) ? 'success.main' : 'black',
    children: name
  });
};