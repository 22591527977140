import AssistantCommonLayout from '../../layouts/common';
import AspBanner from '../../../offer/asp/components/banner';
import useAssistant from '../../hooks/useAssistant';
import { EAssistantScenario } from '../../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const scenarioType = EAssistantScenario.AspAccept;
export const AssistantScenarioAspAcceptContainer = () => {
  const {
    onFinishScenario
  } = useAssistant();
  return _jsx(AssistantCommonLayout, {
    children: _jsx(AspBanner, {
      bonusSize: 13500,
      year: 2025,
      deactivateTimestamp: '2024-12-05',
      onAccept: () => onFinishScenario(scenarioType)
    })
  });
};