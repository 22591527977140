import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import LegalAgreementContainer from 'presentation/features/legal/agreement/container';
import CheckSignsContainer from 'presentation/features/legal/checkSigns/container';
import { useMemo } from 'react';
import { RequiredActionConfirmEmailContainer } from '../presentation/features/requiredAction/confirmEmail/container';
import useTechConfig from '../presentation/hooks/useTechConfig';
import { EAppFeature } from '../presentation/types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AppDecorators = _ref => {
  let {
    children
  } = _ref;
  const {
    hasFeature
  } = useTechConfig();
  const {
    isAuthenticated
  } = useAuth();
  const decorators = useMemo(() => {
    return [isAuthenticated && _jsx(LegalAgreementContainer, {}, 'legalAgreement'), isAuthenticated && _jsx(CheckSignsContainer, {}, 'checkSigns'), isAuthenticated && hasFeature(EAppFeature.ConfirmEmail) && _jsx(RequiredActionConfirmEmailContainer, {}, 'confirmEmail')].filter(item => !!item);
  }, [hasFeature, isAuthenticated]);
  return _jsx(RenderDecoratorContainer, {
    decorators: decorators,
    children: children
  });
};
export default AppDecorators;