import { Link } from '@mui/material';
import UserAttributeLayout from 'presentation/layouts/userAttribute';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = UserAttributeLayout;
const UserProfileViewAttribute = props => {
  const {
    label,
    icon,
    value,
    onTryChange
  } = props;
  const action = onTryChange && _jsx(Link, {
    component: "div",
    color: "primary",
    variant: "caption",
    onClick: onTryChange,
    children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C"
  });
  return _jsx(Layout, {
    label: label,
    icon: icon,
    action: action,
    children: value
  });
};
export default UserProfileViewAttribute;