import { useNsiBookingOrderStatusesQuery, useNsiCancelOrderCustomerTypesQuery, useNsiCancelOrderTypesQuery } from 'data/api/nsi';
import { useCancelOrderMutation, userApi } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferType, EOrderStatus } from 'domain/model/enums';
import { EDateTimeFormat } from 'domain/model/formats';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import { getRequisites, getStatusName } from 'presentation/features/order/components/listItem/adapters/utils';
import { utcToLocalTimeZone } from 'presentation/utils/date';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CancelOrderModal } from '../../../../../../order/cancelOrderModal';
import CancelOption from '../../../components/optionItems/cancel';
import ContactsOption from '../../../components/optionItems/contacts';
import SupportOption from '../../../components/optionItems/support';
import { CommonOptionsWrapper, OptionsWrapper } from '../../../components/options/controls';
import StatusComment from '../../../components/statusComment';
import StatusText from '../../../components/statusText';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const cancellableStatuses = [EOrderStatus.New, EOrderStatus.Confirmed];
const BookingOfferOrderOptionsAdapter = _ref => {
  let {
    order
  } = _ref;
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const {
    data: bookingOrderStatuses,
    isFetching: isBookingOrderStatusesFetcing,
    error: bookingOrderStatusesError
  } = useNsiBookingOrderStatusesQuery();
  const {
    data: cancelOrderTypes,
    isFetching: isCancelOrderTypesFetcing,
    error: cancelOrderTypesError
  } = useNsiCancelOrderTypesQuery();
  const {
    data: cancelCustomerTypes,
    isFetching: isCancelCustomerTypesFetcing,
    error: cancelCustomerTypesError
  } = useNsiCancelOrderCustomerTypesQuery();
  const isFetching = isBookingOrderStatusesFetcing || isCancelOrderTypesFetcing || isCancelCustomerTypesFetcing;
  const [cancelOrder, {
    isLoading,
    error
  }] = useCancelOrderMutation();
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
    if (bookingOrderStatusesError) {
      ErrorHandler.handleHttpError(bookingOrderStatusesError);
    }
    if (cancelOrderTypesError) {
      ErrorHandler.handleHttpError(cancelOrderTypesError);
    }
    if (cancelCustomerTypesError) {
      ErrorHandler.handleHttpError(cancelCustomerTypesError);
    }
  }, [bookingOrderStatusesError, cancelCustomerTypesError, cancelOrderTypesError, error]);
  const handleCancelOrder = (id, comment) => {
    if (!order.id) {
      return;
    }
    cancelOrder({
      id: order.id,
      cancellationType: {
        id
      },
      comment
    }).unwrap().then(response => {
      if (order.id) {
        setModalVisible(false);
        dispatch(userApi.util.updateQueryData('getUserOrder', order.id, draft => {
          Object.assign(draft, response);
        }));
      }
    }).catch(response => ErrorHandler.handleHttpError(response));
  };
  const statusName = bookingOrderStatuses && getStatusName(bookingOrderStatuses, order.status);
  const date = utcToLocalTimeZone(order.createdAt).format(EDateTimeFormat.Human);
  const requisites = getRequisites(order.number, date);
  const isCancellable = cancellableStatuses.includes(order.status);
  if (isFetching || !cancelOrderTypes || !cancelCustomerTypes) {
    return _jsx(ContentLoader, {});
  }
  return _jsxs(CommonOptionsWrapper, {
    children: [_jsx(StatusText, {
      status: statusName ?? '',
      requisites: requisites
    }), _jsx(Splitter, {
      size: 3
    }), order.status === EOrderStatus.Cancelled && _jsx(StatusComment, {
      order: order,
      reasons: [...cancelOrderTypes, ...cancelCustomerTypes]
    }), _jsx(Splitter, {
      size: 3
    }), _jsxs(OptionsWrapper, {
      children: [order.offer?.id && _jsx(ContactsOption, {
        offerId: order.offer.id
      }), isCancellable && _jsx(CancelOption, {
        onClick: () => setModalVisible(true)
      }), _jsx(SupportOption, {})]
    }), _jsx(CancelOrderModal, {
      isOpen: isModalVisible,
      isFetching: isLoading,
      offerType: EOfferType.Booking,
      onCancelOrder: handleCancelOrder,
      onClose: () => setModalVisible(false)
    })]
  });
};
export default BookingOfferOrderOptionsAdapter;