import Splitter from 'presentation/components/common/splitter';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const OnboardingWelcomeBonusesAdapter = _ref => {
  let {
    onNext
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      children: [_jsx("div", {
        children: "\u0412 \u0431\u043B\u0438\u0436\u0430\u0439\u0448\u0435\u0435 \u0432\u0440\u0435\u043C\u044F \u0432\u0430\u0448 \u0431\u0430\u043B\u0430\u043D\u0441 \u0434\u043E\u043B\u0436\u0435\u043D \u043F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C\u0441\u044F \u043D\u0430 15 000 \u20BD. \u041E\u0431\u044B\u0447\u043D\u043E \u044D\u0442\u043E\u0442 \u043F\u0440\u043E\u0446\u0435\u0441\u0441 \u0437\u0430\u043D\u0438\u043C\u0430\u0435\u0442 \u043D\u0435 \u0431\u043E\u043B\u044C\u0448\u0435 \u0441\u0443\u0442\u043E\u043A."
      }), _jsx(Splitter, {
        size: 2
      }), _jsx("div", {
        children: "\u041F\u043E\u043A\u0430 \u043C\u043E\u0436\u043D\u043E \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u043D\u044B\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u0438 \u0434\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0438\u0445 \u0432 \u0418\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435, \u0447\u0442\u043E\u0431\u044B \u043D\u0435 \u043F\u043E\u0442\u0435\u0440\u044F\u0442\u044C."
      })]
    }), _jsx(Splitter, {
      size: 1
    }), _jsx(MPButton, {
      size: "small",
      color: "brand",
      variant: "contained",
      onClick: onNext,
      children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
    })]
  });
};