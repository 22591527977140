import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import { MPSingleDatePicker } from 'presentation/theme/ui-kit/picker';
import React, { useState } from 'react';
import ConfirmButtons from '../../../../theme/ui-kit/dialog/buttons';
import UserAvatarEditorContainer from '../../avatarEditor/container';
import { UserGenderSelector } from '../genderSelector';
import { UserPhotoSelector } from '../photoSelector';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const UserProfileEditAttributeCommonInfo = props => {
  const {
    label,
    isFetching,
    user,
    validation,
    onChange,
    onSave,
    onClose,
    isCorpUser
  } = props;
  const [newPhoto, setNewPhoto] = useState(null);
  const onChangePhoto = photo => {
    onChange('photo', photo);
    setNewPhoto(null);
  };
  return _jsxs(_Fragment, {
    children: [_jsx(MPConfirmDialog, {
      open: true,
      title: label,
      maxWidth: "xs",
      fullScreenBreakpoint: "sm",
      scroll: "body",
      buttons: _jsxs(ConfirmButtons, {
        children: [_jsxs(MPButton, {
          fullWidth: true,
          color: "brand",
          size: "large",
          disabled: isFetching,
          onClick: onSave,
          children: ["\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", isFetching && _jsx(ContentLoader, {})]
        }), _jsx(MPButton, {
          color: "brand",
          size: "large",
          fullWidth: true,
          variant: "text",
          disabled: isFetching,
          onClick: onClose,
          children: "\u041E\u0442\u043C\u0435\u043D\u0430"
        })]
      }),
      onClose: onClose,
      children: _jsxs(MPGrid, {
        container: true,
        spacing: 2,
        direction: "column",
        children: [_jsxs(MPGrid, {
          item: true,
          children: [_jsx(UserPhotoSelector, {
            src: user.photo?.path,
            onChange: setNewPhoto,
            onClear: () => onChangePhoto(null)
          }), _jsx(Splitter, {
            size: 2
          })]
        }), _jsx(MPGrid, {
          item: true,
          children: _jsx(MPFormInput, {
            label: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F",
            value: user.lastName ?? '',
            error: !!validation?.lastName?.hasError,
            helperText: validation?.lastName?.message,
            disabled: isFetching || isCorpUser,
            onChange: event => onChange('lastName', event.target.value)
          })
        }), _jsx(MPGrid, {
          item: true,
          children: _jsx(MPFormInput, {
            label: "\u0418\u043C\u044F",
            value: user.firstName ?? '',
            error: !!validation?.firstName?.hasError,
            helperText: validation?.firstName?.message,
            disabled: isFetching || isCorpUser,
            onChange: event => onChange('firstName', event.target.value)
          })
        }), _jsx(MPGrid, {
          item: true,
          children: _jsx(MPFormInput, {
            label: "\u041E\u0442\u0447\u0435\u0441\u0442\u0432\u043E",
            value: user.middleName ?? '',
            error: !!validation?.middleName?.hasError,
            helperText: validation?.middleName?.message,
            disabled: isFetching || isCorpUser,
            onChange: event => onChange('middleName', event.target.value)
          })
        }), _jsx(MPGrid, {
          item: true,
          children: _jsxs(MPGrid, {
            container: true,
            spacing: 2,
            wrap: "nowrap",
            children: [_jsx(MPGrid, {
              item: true,
              zero: true,
              children: _jsx(MPSingleDatePicker, {
                label: "\u0414\u0430\u0442\u0430 \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F",
                value: user.birthDate && moment(user.birthDate).toDate() || null,
                disabled: isFetching || isCorpUser,
                error: !!validation?.birthDate?.hasError,
                helperText: validation?.birthDate?.message,
                onChange: value => {
                  if (isCorpUser) {
                    return;
                  }
                  onChange('birthDate', value ? moment(value).format(EDateFormat.Server) : null);
                }
              })
            }), _jsx(MPGrid, {
              item: true,
              children: _jsx(UserGenderSelector, {
                value: user.gender ?? null,
                disabled: isFetching || isCorpUser,
                onChange: value => onChange('gender', value),
                error: !!validation?.gender?.hasError,
                helperText: validation?.gender?.message
              })
            })]
          })
        })]
      })
    }), newPhoto && _jsx(UserAvatarEditorContainer, {
      source: newPhoto,
      onClose: () => setNewPhoto(null),
      onChange: onChangePhoto
    })]
  });
};