import { CardContent } from '@mui/material';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AssistantCommonLayout = _ref => {
  let {
    children
  } = _ref;
  return _jsx(Wrapper, {
    children: _jsx(CardContent, {
      children: children
    })
  });
};
export default AssistantCommonLayout;