import { Hidden } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import { SwiperSlide } from 'swiper/react';
import Splitter from '../../splitter';
import CarouselDefault from '../carouselDefault';
import useGetDefaultCarouselNavigation from '../defaultNavigation/useGetDefaultCarouselNavigation';
import CarouselThumbs from '../thumbs';
import { CarouselCommonWrapper, DefaultCarouselWrapper, ThumbCarouselWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CarouselGallery = _ref => {
  let {
    children,
    initSlide,
    showThumbs = true,
    // TODO(@Protopopov Ruslan): глючит при изменении, нужна реинициализация swiper
    thumbScrollMode = false,
    mainSwiperContainerHeight,
    thumbSwiperContainerHeight,
    swipersGap,
    thumbChildren,
    aspectRatio,
    onActiveIndexChange,
    mainSwiperProps = {
      spaceBetween: 10,
      navigation: false,
      pagination: false,
      loop: true
    },
    thumbSwiperProps = {
      spaceBetween: 10,
      loop: true,
      slidesPerView: 4
    }
  } = _ref;
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const childrenCount = React.Children.count(children);
  const detailSlides = children?.map((child, index) => _jsx(SwiperSlide, {
    children: child
  }, index)) ?? [];
  const finalThumbChildren = thumbChildren ?? children;
  const thumbSlides = finalThumbChildren?.map((child, index) => _jsx(SwiperSlide, {
    children: child
  }, index));
  const finalShowThumbs = showThumbs && !!(thumbSlides?.length && thumbSlides.length > 1);
  const handlerChangeActiveIndex = swiper => {
    onActiveIndexChange?.(swiper.realIndex);
  };
  const controlsElement = useGetDefaultCarouselNavigation({
    navigationEnabled: !!mainSwiperProps?.navigation,
    childrenCount,
    swiper
  });
  useEffect(() => {
    if (initSlide) {
      swiper?.slideTo(initSlide, 0);
      thumbsSwiper?.slideTo(initSlide, 0);
    }
  }, [initSlide, swiper, thumbsSwiper]);
  const internalLoop = useMemo(() => {
    if (typeof thumbSwiperProps.slidesPerView === 'string') {
      return true;
    }
    if (!thumbSwiperProps?.slidesPerView || !thumbSlides?.length) {
      return false;
    }
    return thumbSlides.length >= thumbSwiperProps.slidesPerView;
  }, [thumbSlides?.length, thumbSwiperProps?.slidesPerView]);
  const internalThumbSwiperProps = {
    ...thumbSwiperProps,
    loop: internalLoop
  };
  return _jsxs(CarouselCommonWrapper, {
    children: [_jsx(DefaultCarouselWrapper, {
      height: mainSwiperContainerHeight,
      children: _jsx(CarouselDefault, {
        controlsElement: controlsElement,
        swiperProps: mainSwiperProps,
        onSwiper: setSwiper,
        handlerChangeActiveIndex: handlerChangeActiveIndex,
        thumbsSwiper: thumbsSwiper,
        aspectRatio: aspectRatio,
        children: detailSlides
      })
    }), finalShowThumbs && _jsxs(Hidden, {
      smDown: true,
      implementation: "css",
      children: [_jsx(Splitter, {
        size: `${swipersGap}px`
      }), _jsx(ThumbCarouselWrapper, {
        height: thumbSwiperContainerHeight,
        children: _jsx(CarouselThumbs, {
          onSwiper: setThumbsSwiper,
          thumbScrollMode: thumbScrollMode,
          swiperProps: internalThumbSwiperProps,
          children: thumbSlides
        })
      })]
    })]
  });
};
export default CarouselGallery;