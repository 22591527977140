import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** todo по идее надо как-то централизованно с ним работать, а не из фич */
const UserNeedFillProfileDialog = props => {
  const {
    open,
    message,
    onFill,
    onClose
  } = props;
  return _jsx(MPConfirmDialog, {
    title: "\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0437\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u043F\u0440\u043E\u0444\u0438\u043B\u044C",
    open: open,
    maxWidth: "xs",
    onClose: onClose,
    buttons: _jsx(MPButton, {
      fullWidth: true,
      color: "brand",
      size: "large",
      onClick: onFill,
      children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u043F\u0440\u043E\u0444\u0438\u043B\u044C"
    }),
    children: message || 'Для получения предложения необходимо заполнить профиль'
  });
};
export default UserNeedFillProfileDialog;