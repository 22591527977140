import { CloseIcon } from 'presentation/media/icons';
import AssistantCallButton from '../../components/callButton';
import { CallButtonWrapper, CloseButton, Header, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AssistantLayout = _ref => {
  let {
    open,
    onClose,
    onToogle,
    children
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx(CallButtonWrapper, {
      children: _jsx(AssistantCallButton, {
        open: open,
        onClick: onToogle
      })
    }), _jsxs(Wrapper, {
      open: open,
      children: [open && _jsx(Header, {
        variant: "subtitle2",
        children: "\u0410\u0441\u0441\u0438\u0441\u0442\u0435\u043D\u0442"
      }), onClose && _jsx(CloseButton, {
        size: 'small',
        onClick: onClose,
        children: _jsx(CloseIcon, {
          fontSize: 'small'
        })
      }), children]
    })]
  });
};
export default AssistantLayout;