import { Link, Typography } from '@mui/material';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useEmailEditQuery } from 'presentation/hooks/useEmailEditQuery';
import { useCallback, useEffect, useState } from 'react';
import { RequiredActionConfirmCodeContainer } from '../../confirmCode/container';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ChangeEmail = props => _jsx(Link, {
  color: "primary",
  component: Typography,
  underline: "hover",
  ...props,
  children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u043E\u0447\u0442\u0443"
});
export const ConfirmAdapter = _ref => {
  let {
    onClose,
    onChangeEmail
  } = _ref;
  const {
    tokenParsed,
    refreshToken
  } = useAuthToken();
  const [source, setSource] = useState(null);
  const [updateCurrentUserEmail, {
    isLoading: isFetching,
    error
  }] = useEmailEditQuery();
  const email = tokenParsed?.email ?? '';
  const onGetCode = useCallback(() => updateCurrentUserEmail({
    email
  }).unwrap().then(setSource).catch(console.error), [email, updateCurrentUserEmail]);
  const onSuccess = () => {
    refreshToken();
    onClose();
  };
  useEffect(() => {
    onGetCode();
  }, [onGetCode]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
      setSource(null);
      onClose();
    }
  }, [error, onClose]);
  return _jsxs(_Fragment, {
    children: [source && _jsx(RequiredActionConfirmCodeContainer, {
      email: email,
      source: source,
      onSuccess: onSuccess,
      onGetNewCode: onGetCode,
      back: _jsx(ChangeEmail, {
        onClick: onChangeEmail
      }),
      onClose: onClose
    }), isFetching && _jsx(ContentLoader, {})]
  });
};