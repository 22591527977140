import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmButtons from '../../../../theme/ui-kit/dialog/buttons';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserResetPasswordConfirmDialog = props => {
  const {
    open,
    isFetching,
    onConfirm,
    onClose
  } = props;
  return _jsx(MPConfirmDialog, {
    title: "\u0421\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C?",
    open: open,
    maxWidth: "xs",
    onClose: onClose,
    buttons: _jsxs(ConfirmButtons, {
      children: [_jsxs(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        disabled: isFetching,
        onClick: onConfirm,
        children: ["\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C", isFetching && _jsx(ContentLoader, {})]
      }), _jsx(MPButton, {
        variant: "text",
        color: "brand",
        size: "large",
        fullWidth: true,
        disabled: isFetching,
        onClick: onClose,
        children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C"
      })]
    }),
    children: "\u0414\u043B\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u043F\u0430\u0440\u043E\u043B\u044F \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0432\u0445\u043E\u0434 \u0441 \u0442\u0435\u043A\u0443\u0449\u0438\u043C \u043F\u0430\u0440\u043E\u043B\u0435\u043C, \u043F\u043E\u0441\u043B\u0435 \u0447\u0435\u0433\u043E \u0432\u044B \u0441\u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u0432\u0435\u0441\u0442\u0438 \u043D\u043E\u0432\u044B\u0439."
  });
};
export default UserResetPasswordConfirmDialog;