import { useMemo } from 'react';
import useUserLocalSettings from '../../hooks/useUserLocalSettings';
import { EOnboardingStepType } from './types';
import { OnboardingAspAcceptAdapter, OnboardingCompletedAdapter, OnboardingCookieAcceptAdapter, OnboardingSelectLocalityAdapter, OnboardingWelcomeBonusesAdapter } from './adapters';
import { useAuthUser } from '../auth/provider/useAuthUser';
const useOnboarding = () => {
  const {
    isCorpUser
  } = useAuthUser();
  const {
    initialSettings
  } = useUserLocalSettings();
  const hasInitialCity = !!initialSettings?.city?.fiasId;
  const hasCookieAccept = initialSettings?.cookieAccepted ?? false;
  const steps = useMemo(() => {
    const result = [];
    if (!hasInitialCity) {
      result.push({
        type: EOnboardingStepType.SelectLocality,
        component: OnboardingSelectLocalityAdapter
      });
    }
    if (!hasCookieAccept) {
      result.push({
        type: EOnboardingStepType.CookieAccept,
        component: OnboardingCookieAcceptAdapter
      });
    }
    if (!isCorpUser) {
      result.push({
        type: EOnboardingStepType.WelcomeBonuses,
        component: OnboardingWelcomeBonusesAdapter
      });
      result.push({
        type: EOnboardingStepType.AspAccept,
        component: OnboardingAspAcceptAdapter
      });
    }
    if (result.length) {
      result.push({
        type: EOnboardingStepType.Completed,
        component: OnboardingCompletedAdapter
      });
    }
    return result;
  }, [hasInitialCity, hasCookieAccept, isCorpUser]);
  return {
    steps
  };
};
export default useOnboarding;