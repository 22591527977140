import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorHandler from 'data/network/errorHandler';
import BookingSidebar from '../../components/sidebar';
import UserPrompt from '../../components/userPrompt';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { bookingOfferDetailsCartSelector } from '../store/selectors';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferSidebarAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  const [confirmDeleteId, setConfirmDeleteId] = useState([-1]);
  const [customerComment, setCustomerComment] = useState('');
  const cart = useSelector(bookingOfferDetailsCartSelector);
  const {
    onUpdateModal,
    onDeleteService,
    isOrderCreating,
    errorCreateOrder,
    onConfirmPhoneDialogOpen
  } = useContextHandlers();
  useEffect(() => {
    if (errorCreateOrder) {
      ErrorHandler.handleHttpError(errorCreateOrder);
    }
  }, [errorCreateOrder]);
  if (!bookingOffer) {
    return null;
  }
  const handleDeleteItem = index => {
    setConfirmDeleteId([-1]);
    onDeleteService(index);
  };
  return _jsxs(_Fragment, {
    children: [_jsx(BookingSidebar, {
      isLoading: isOrderCreating,
      offerId: bookingOffer.code,
      cart: cart?.[id] ?? null,
      customerComment: customerComment,
      onSelectItem: onUpdateModal,
      onDeleteItem: setConfirmDeleteId,
      onOrder: onConfirmPhoneDialogOpen,
      onChangeCustomerComment: setCustomerComment
    }), _jsx(UserPrompt, {
      isVisible: confirmDeleteId[0] > -1,
      title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0443\u0441\u043B\u0443\u0433\u0443?",
      text: "\u0423\u0441\u043B\u0443\u0433\u0430 \u0431\u0443\u0434\u0435\u0442 \u0443\u0434\u0430\u043B\u0435\u043D\u0430 \u0438\u0437 \u043A\u043E\u0440\u0437\u0438\u043D\u044B",
      onProceed: () => handleDeleteItem(confirmDeleteId),
      onCancel: () => setConfirmDeleteId([-1])
    })]
  });
};
export default BookingOfferSidebarAdapter;