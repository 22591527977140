import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import Splitter from 'presentation/components/common/splitter';
import ActivationListItemExpireDate from 'presentation/features/activation/components/sidebar/expiryDate/title';
import ActivationListItemLabel from 'presentation/features/activation/components/sidebar/label/title';
import { OfferPromotionDownload } from 'presentation/features/offer/components/promotionDownload';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { ECorpOfferPromotionType } from '../../../../../../domain/model/enums';
import { getLeftSeconds } from '../../../../../utils/date';
import { SidebarWrapper } from '../controls';
import PartnerLink from 'presentation/components/common/partnerLink';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemFileSidebar = props => {
  const {
    expireDate,
    promotionType,
    appointmentDate,
    codeTypeName,
    partnerLink,
    downloadLabel,
    onPartnerLinkClick,
    isDownloading,
    onDownload
  } = props;
  const expireDateLocal = moment(expireDate);
  const activationExpired = expireDate && getLeftSeconds(expireDateLocal) <= 0;
  const isCertificate = promotionType === ECorpOfferPromotionType.Certificate || promotionType === ECorpOfferPromotionType.ExternalCertificate;
  return _jsx(SidebarWrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 1,
      direction: "column",
      children: [_jsxs(MPGrid, {
        item: true,
        children: [_jsx(ActivationListItemLabel, {
          codeTypeName: codeTypeName,
          appointmentDate: appointmentDate
        }), expireDate && _jsx(ActivationListItemExpireDate, {
          expireDate: expireDate
        })]
      }), isCertificate && activationExpired && _jsx(MPGrid, {
        item: true,
        children: _jsxs(Typography, {
          color: "error",
          children: ["\u0421\u0440\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u0430 \u0438\u0441\u0442\u0435\u043A ", moment(expireDateLocal).format(EDateFormat.Human), " \u0433\u043E\u0434\u0430."]
        })
      }), !(isCertificate && activationExpired) && _jsx(MPGrid, {
        item: true,
        children: _jsx(OfferPromotionDownload, {
          label: downloadLabel,
          onClick: onDownload,
          isFetching: isDownloading
        })
      }), !(isCertificate && activationExpired) && partnerLink && onPartnerLinkClick && _jsxs(MPGrid, {
        item: true,
        children: [_jsx(Splitter, {
          variant: "horizontal",
          size: 1
        }), _jsx(PartnerLink, {
          link: partnerLink,
          onClick: onPartnerLinkClick
        })]
      })]
    })
  });
};
export default ActivationListItemFileSidebar;