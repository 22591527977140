import { useUserFeedbackMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { getPersonFullName } from 'presentation/utils';
import { useEffect, useState } from 'react';
import ContentLoader from '../../../components/common/loader';
import useValidation from '../../../hooks/validation/useValidation';
import { MPButton } from '../../../theme/ui-kit/button';
import ConfirmButtons from '../../../theme/ui-kit/dialog/buttons';
import AboutFeedBack from './component';
import AboutFeedbackLayout from './layout';
import SuccessFeedBack from './success';
import { userFeedBackValidation } from './validation';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Layout = AboutFeedbackLayout;
const emptyData = {
  name: '',
  email: '',
  message: '',
  attachment: null
};
const AboutFeedbackContainer = props => {
  const {
    open,
    onClose
  } = props;
  const {
    user
  } = useAuthUser();
  const [feedBackData, setFeedBackData] = useState(emptyData);
  const [validateOnChangeFeedBack, setValidateOnChangeFeedBack] = useState(false);
  const [userFeedback, {
    error: userFeedbackError,
    isSuccess: isSent,
    isLoading: isExecuting,
    reset: resetSent
  }] = useUserFeedbackMutation();
  const {
    validationResult,
    validate,
    resetValidationResult
  } = useValidation({
    object: feedBackData,
    validateOnChange: validateOnChangeFeedBack,
    rules: userFeedBackValidation
  });
  const onCloseInternal = () => {
    setValidateOnChangeFeedBack(false);
    setFeedBackData(emptyData);
    resetSent();
    resetValidationResult();
    onClose();
  };
  const onChangeAttribute = (name, value) => {
    setFeedBackData(prevFeedBackData => {
      return prevFeedBackData ? {
        ...prevFeedBackData,
        [name]: value
      } : prevFeedBackData;
    });
  };
  const onSend = () => {
    if (!validate()) {
      setValidateOnChangeFeedBack(true);
    } else {
      userFeedback(feedBackData);
    }
  };
  useEffect(() => {
    if (userFeedbackError) {
      ErrorHandler.handleHttpError(userFeedbackError);
    }
  }, [userFeedbackError]);
  useEffect(() => {
    if (open) {
      if (user) {
        setFeedBackData({
          ...emptyData,
          name: getPersonFullName(user),
          email: user.email
        });
      } else {
        setFeedBackData({
          ...emptyData,
          name: '',
          email: ''
        });
      }
    }
  }, [open, user]);
  useEffect(() => {
    if (isSent) {
      setValidateOnChangeFeedBack(false);
      setFeedBackData(emptyData);
      onClose();
    }
  }, [isSent, onClose]);
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);
  return _jsxs(_Fragment, {
    children: [_jsx(Layout, {
      open: open,
      title: "\u0424\u043E\u0440\u043C\u0430 \u043E\u0431\u0440\u0430\u0442\u043D\u043E\u0439 \u0441\u0432\u044F\u0437\u0438",
      onClose: onCloseInternal,
      buttons: _jsxs(ConfirmButtons, {
        children: [_jsxs(MPButton, {
          fullWidth: true,
          color: "brand",
          size: "large",
          disabled: isExecuting,
          onClick: onSend,
          children: ["\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C", isExecuting && _jsx(ContentLoader, {})]
        }), _jsx(MPButton, {
          color: "brand",
          fullWidth: true,
          size: "large",
          variant: "text",
          disabled: isExecuting,
          onClick: onCloseInternal,
          children: "\u041E\u0442\u043C\u0435\u043D\u0430"
        })]
      }),
      children: _jsx(AboutFeedBack, {
        data: feedBackData,
        isSending: isExecuting,
        validation: validationResult,
        onChangeAttribute: onChangeAttribute
      })
    }), _jsx(SuccessFeedBack, {
      open: isSent,
      onClose: resetSent
    })]
  });
};
export default AboutFeedbackContainer;