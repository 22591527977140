import CorpLinkProxy from 'components/common/links/proxy';
import { getCorpOffersListRoute } from 'presentation/features/offer/corp/routes';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useHistory } from 'react-router';
import { AppConfigurator } from 'system/appConfigurator';
import { BalanceSlotActions } from '../../layout/controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceSlotActionsAdapter = () => {
  const history = useHistory();
  const corpEarningUrl = AppConfigurator.getInstance().getCorpEarningsSystemUrl();
  const onBy = () => history.push(getCorpOffersListRoute({}));
  return _jsxs(BalanceSlotActions, {
    children: [_jsx(MPButton, {
      variant: "contained",
      color: "brand",
      fullWidth: true,
      onClick: onBy,
      children: "\u041F\u043E\u0442\u0440\u0430\u0442\u0438\u0442\u044C"
    }), corpEarningUrl && _jsx(CorpLinkProxy, {
      link: corpEarningUrl,
      children: linkByProxy => _jsx(MPButton, {
        variant: "outlined",
        color: "brand",
        fullWidth: true,
        href: linkByProxy,
        target: "_blank",
        children: "\u041A\u0430\u043A \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C"
      })
    })]
  });
};