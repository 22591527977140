import apiDefinition from '../openApi';
import { api, EUserServicesTag } from './index';
export const cmsApi = api.injectEndpoints({
  endpoints: builder => ({
    getCmsPartnerPage: builder.query({
      keepUnusedDataFor: 3600,
      query: _ref => {
        let {
          id
        } = _ref;
        return apiDefinition.partner.page({
          partnerId: id
        });
      },
      transformResponse: response => response.data,
      providesTags: [EUserServicesTag.Location]
    }),
    getCmsLandingPage: builder.query({
      keepUnusedDataFor: 3600,
      query: () => apiDefinition.user.landing.window({}),
      transformResponse: response => response.data,
      providesTags: [EUserServicesTag.Location]
    }),
    getCmsContainerBannersContent: builder.query({
      keepUnusedDataFor: 3600,
      query: _ref2 => {
        let {
          bannerIds
        } = _ref2;
        return apiDefinition.cms.banner.byIds({
          ids: bannerIds
        });
      },
      transformResponse: (response, _, queryArg) => {
        const parsedArray = [];
        queryArg.bannerIds.forEach(id => {
          const banner = response.data.find(banner => banner.id === id);
          if (banner) parsedArray.push(banner);
        });
        return parsedArray;
      },
      providesTags: [EUserServicesTag.Location]
    }),
    getCmsCollection: builder.query({
      keepUnusedDataFor: 3600,
      query: _ref3 => {
        let {
          id
        } = _ref3;
        return apiDefinition.cms.collection.one({
          id
        });
      },
      transformResponse: response => response.data,
      providesTags: [EUserServicesTag.Location]
    }),
    getCmsTradeOffersCollection: builder.query({
      keepUnusedDataFor: 3600,
      transformResponse: (response, meta, _ref4) => {
        let {
          statuses
        } = _ref4;
        return response.data.filter(item => !statuses?.length || statuses.includes(item.status));
      },
      query: _ref5 => {
        let {
          id
        } = _ref5;
        return apiDefinition.user.trade.collection({
          id
        });
      },
      providesTags: [EUserServicesTag.Location]
    }),
    getCmsCorpOffersCollection: builder.query({
      keepUnusedDataFor: 3600,
      query: _ref6 => {
        let {
          id
        } = _ref6;
        return apiDefinition.user.corp.collection({
          id
        });
      },
      transformResponse: (response, meta, _ref7) => {
        let {
          statuses
        } = _ref7;
        return response.data.filter(item => !statuses?.length || statuses.includes(item.status));
      },
      providesTags: [EUserServicesTag.Location]
    }),
    getCmsPartnerDesksCollection: builder.query({
      keepUnusedDataFor: 3600,
      query: _ref8 => {
        let {
          id
        } = _ref8;
        return apiDefinition.partner.desk.collection({
          id
        });
      },
      transformResponse: response => response.data,
      providesTags: [EUserServicesTag.Location]
    })
  })
});
export const {
  useGetCmsPartnerPageQuery,
  useGetCmsLandingPageQuery,
  useGetCmsContainerBannersContentQuery,
  useGetCmsCollectionQuery,
  useGetCmsTradeOffersCollectionQuery,
  useGetCmsCorpOffersCollectionQuery,
  useGetCmsPartnerDesksCollectionQuery
} = cmsApi;