import Splitter from 'presentation/components/common/splitter';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const OnboardingCompletedAdapter = _ref => {
  let {
    onNext
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      children: "\u041C\u044B \u0432\u0441\u0451 \u043D\u0430\u0441\u0442\u0440\u043E\u0438\u043B\u0438!"
    }), _jsx(Splitter, {
      size: 1
    }), _jsx(MPButton, {
      fullWidth: true,
      size: "small",
      color: "brand",
      variant: "contained",
      onClick: onNext,
      children: "\u041D\u0430\u0447\u0430\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F"
    })]
  });
};