import { useResetCurrentUserPasswordMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import UserProfileViewAttribute from 'presentation/features/user/components/attributeView';
import UserResetPasswordConfirmDialog from 'presentation/features/user/components/resetPasswordConfirmDialog';
import { KeyAltIcon } from 'presentation/media/icons';
import { useEffect, useState } from 'react';
import { useAuthUser } from '../../../../auth/provider/useAuthUser';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const label = 'Пароль для входа';
export const UserProfilePasswordAdapter = () => {
  const {
    logOut
  } = useAuth();
  const {
    onResetPassword: onResetPasswordMP
  } = useAuthUser();
  const [editMode, setEditMode] = useState(false);
  const [, {
    isLoading: resetCurrentUserPasswordFetching,
    error: resetCurrentUserPasswordError
  }] = useResetCurrentUserPasswordMutation();
  const showEdit = () => setEditMode(true);
  const hideEdit = () => setEditMode(false);
  const onResetPassword = () => {
    onResetPasswordMP?.().then(r => {
      if (r !== true) {
        return;
      }
      hideEdit();
      logOut();
    });
  };
  useEffect(() => {
    if (resetCurrentUserPasswordError) {
      ErrorHandler.handleHttpError(resetCurrentUserPasswordError);
    }
  }, [resetCurrentUserPasswordError]);
  return _jsxs(_Fragment, {
    children: [_jsx(UserProfileViewAttribute, {
      label: label,
      value: '••••••••••••••••',
      icon: _jsx(KeyAltIcon, {
        color: "secondary"
      }),
      onTryChange: showEdit
    }), editMode && _jsx(UserResetPasswordConfirmDialog, {
      open: true,
      isFetching: resetCurrentUserPasswordFetching ?? false,
      onConfirm: onResetPassword,
      onClose: hideEdit
    })]
  });
};