import { useEffect, useState } from 'react';
import useUserLocalSettings from '../../../../../hooks/useUserLocalSettings';
import { AddressHelper } from '../../../../../../utils/address';
export const useUserProfileLocalityEdit = props => {
  const {
    user
  } = props;
  const [value, setValue] = useState(() => user.locality);
  const {
    setCity
  } = useUserLocalSettings();
  const data = {
    ...user,
    locality: value
  };
  const update = () => {
    if (data.locality) {
      return setCity(data.locality.id, new AddressHelper(data.locality).getLocalitySimpleName());
    }
  };
  const reset = () => {
    /*resetValidationResult();*/
    setValue(user.locality);
  };
  useEffect(() => {
    setValue(user.locality);
  }, [user.locality]);
  return {
    value,
    validation: null,
    update,
    reset,
    onChange: setValue
  };
};