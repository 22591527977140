import { useEffect, useState } from 'react';
import useAssistant from '../../hooks/useAssistant';
import { EAssistantScenario } from '../../types';
import { AssistantScenarioOnboardingContainer } from './container';
const scenarioType = EAssistantScenario.Onboarding;

/**
 * сценарий первого входа пользователя
 * условие срабатывания - пользователь впервые входит в данном браузере
 */
export const AssistantScenarioOnboardingManager = () => {
  const {
    onActivateScenario,
    onFinishScenario
  } = useAssistant();
  const [isFirstStart] = useState(true);
  useEffect(() => {
    if (isFirstStart) {
      onActivateScenario(scenarioType);
    } else {
      onFinishScenario(scenarioType);
    }
  }, [isFirstStart, onActivateScenario, onFinishScenario]);
  return null;
};
export const config = {
  type: scenarioType,
  required: true,
  manager: AssistantScenarioOnboardingManager,
  component: AssistantScenarioOnboardingContainer
};