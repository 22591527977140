import { useSelector } from 'react-redux';
import { assistantIsScenarioPresentSelector } from '../store/selectors';
const AssistantScenarioActivator = _ref => {
  let {
    type,
    children
  } = _ref;
  const isPresent = useSelector(assistantIsScenarioPresentSelector(type));
  return isPresent && children;
};
export default AssistantScenarioActivator;