import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../data/store/store';
import { assistantOpen, assistantReadyScenariosUpTime, assistantSetScenarios, assistantStartScenario } from '../store/slice';
import { EAssistantScenarioStatus } from '../types';
import moment from 'moment-timezone';
const getState = () => store.getState().assistant;
const checkInterval = 3000;
const useAssistantWidget = _ref => {
  let {
    scenarios
  } = _ref;
  const dispatch = useDispatch();
  useEffect(() => {
    const config = scenarios.map(_ref2 => {
      let {
        component,
        manager,
        ...others
      } = _ref2;
      return others;
    });
    dispatch(assistantSetScenarios(config));
  }, [dispatch, scenarios]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const {
        activeScenario,
        scenarios
      } = getState();
      if (!activeScenario) {
        const nextReadyScenario = scenarios?.find(s => s.status === EAssistantScenarioStatus.Ready) ?? null;
        if (nextReadyScenario) {
          if (nextReadyScenario.interval) {
            if (moment().diff(nextReadyScenario.changeTime) >= nextReadyScenario.interval) {
              dispatch(assistantStartScenario(nextReadyScenario.type));
              dispatch(assistantOpen());
            }
          } else {
            dispatch(assistantStartScenario(nextReadyScenario.type));
            dispatch(assistantOpen());
          }
        }
      } else {
        dispatch(assistantReadyScenariosUpTime(Date.now()));
      }
    }, checkInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);
};
export default useAssistantWidget;