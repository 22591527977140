import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { assistantActivateScenario, assistantClose, assistantFinishScenario, assistantOpen, assistantToggle } from '../store/slice';
const useAssistant = () => {
  const dispatch = useDispatch();
  const onOpen = useCallback(() => dispatch(assistantOpen()), [dispatch]);
  const onClose = useCallback(() => dispatch(assistantClose()), [dispatch]);
  const onToggle = useCallback(() => dispatch(assistantToggle()), [dispatch]);
  const onActivateScenario = useCallback(type => {
    const changeTime = Date.now();
    dispatch(assistantActivateScenario({
      type,
      changeTime
    }));
  }, [dispatch]);
  const onFinishScenario = useCallback(type => dispatch(assistantFinishScenario(type)), [dispatch]);
  return {
    onOpen,
    onClose,
    onToggle,
    onActivateScenario,
    onFinishScenario
  };
};
export default useAssistant;