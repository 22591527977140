import { ECorpOfferPromotionType, EOfferActivationStatus } from 'domain/model/enums';
import { OfferPromotionDownload } from '../../../../components/promotionDownload';
import { getOfferPromotionDownloadUrl } from '../../../../utils';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimPromotionAdapter = () => {
  const {
    corpOffer,
    activation,
    onTakeActivation,
    isTaking
  } = useCorpOfferClaimContext();
  const {
    lastActivation
  } = activation;
  const promotionDownloadUrl = activation.lastActivation ? getOfferPromotionDownloadUrl(activation.lastActivation) : null;
  const renderPromotions = () => {
    if (!lastActivation) {
      return null;
    }
    switch (lastActivation.promotion.type) {
      case ECorpOfferPromotionType.Certificate:
        return corpOffer && (activation.lastActivation?.status === EOfferActivationStatus.Approved || activation.lastActivation?.status === EOfferActivationStatus.Given) && promotionDownloadUrl && _jsx(OfferPromotionDownload, {
          label: lastActivation.promotion.code,
          onClick: () => onTakeActivation(lastActivation),
          isFetching: isTaking
        });
      default:
        return null;
    }
  };
  return renderPromotions();
};