//атрибут для DOM, чтобы определять направление скролла
const prevScrollAttribute = 'data-prev-scroll';
//смещение сверху для соблюдения минимального отступа от границы экрана
const topOffset = 8;
//смещение снизе для соблюдения минимального отступа от границы экрана
const bottomOffset = 24;
//режим отладки
const debug = false;
export const generateAsideStyles = props => {
  const {
    initialTop,
    asideElement,
    contentElement,
    headerHeight,
    footerHeight
  } = props;
  const windowHeight = window.innerHeight;
  const viewport = windowHeight - headerHeight;
  const isInitialState = !asideElement.hasAttribute(prevScrollAttribute);
  const prevScroll = parseInt(asideElement.getAttribute(prevScrollAttribute) ?? '0') || 0;
  const currentScroll = window.scrollY;
  const {
    top,
    bottom,
    height
  } = asideElement.getBoundingClientRect();
  const offsetHeight = height + bottomOffset + footerHeight;
  const {
    top: contentTop,
    bottom: contentBottom,
    height: contentHeight
  } = contentElement.getBoundingClientRect();
  asideElement.setAttribute(prevScrollAttribute, currentScroll.toString(10));
  log(`currentScroll=${currentScroll}`, `top=${top}`, `initialTop=${initialTop}`, `bottom=${bottom}`, `height=${height}`, `contentBottom=${contentBottom}`, `contentTop=${contentTop}`, `contentHeight=${contentHeight}`, `offsetHeight=${offsetHeight}`, `headerHeight=${headerHeight}`, `footerHeight=${footerHeight}`, `windowHeight=${windowHeight}`);
  if (isInitialState) {
    //начальное состояние - нужно правильно спозиционировать элемент
    if (currentScroll > 0) {
      if (height + currentScroll - (headerHeight + topOffset) > contentBottom) {
        /**
         * технически:
         *  - нижняя граница с учетом смещения равна или выше высоты экрана
         *  - и высота элемента с учетом смещения больше чем высота вьюпорта (эта проверка нужна для того, чтобы маленькие элементы которые и так вмещаются в область контента не фиксировались)
         * логически:
         *  - достижение нижней границы элемента
         * реакция:
         *  - фиксируем по нижней границе
         */
        return absoluteBottom(asideElement, 0);
      } else {
        return absoluteTop(asideElement, 0);
      }
    }
    return null;
  }

  //алгоритм работает в динамике, то есть нельзя пропустить через него значения и спозиционировать элемент
  if (prevScroll < currentScroll) {
    //прокрутка вниз

    if (bottom >= contentBottom) {
      /**
       * технически:
       *  - нижняя граница равна или ниже границы контента
       * логически:
       *  - достижение конца контента
       * реакция:
       *  - прекращаем фиксацию, прижимаем низ элемента к низу контента
       */
      return absoluteBottom(asideElement, 0);
    } else if (bottom + bottomOffset + footerHeight <= windowHeight && offsetHeight > viewport) {
      /**
       * технически:
       *  - нижняя граница с учетом смещения равна или выше высоты экрана
       *  - и высота элемента с учетом смещения больше чем высота вьюпорта (эта проверка нужна для того, чтобы маленькие элементы которые и так вмещаются в область контента не фиксировались)
       * логически:
       *  - достижение нижней границы элемента
       * реакция:
       *  - фиксируем по нижней границе
       */
      return fixBottom(asideElement, bottomOffset + footerHeight);
    } else if (top <= headerHeight + topOffset && offsetHeight < viewport) {
      /**
       * технически:
       *  - верхняя граница равна или выше нижней границы хедера с учетом смещения
       *  - и высота элемента с учетом смещения меньше чем высота вьюпорта (эта проверка нужна для того, чтобы маленькие элементы которые и так вмещаются в область контента фиксировались по верхней границе при прокрутке вниз)
       * логически:
       *  - достижение хедера
       * реакция:
       *  - фиксируем по нижней границе хедера с учетом смещения
       */
      return fixTop(asideElement, headerHeight + topOffset);
    } else {
      /**
       * логически:
       *  - никакие граничные случаи не достигнуты
       * реакция:
       *  - фиксируем на текущей позиции чтобы прокручивалось с прокруткой
       */
      return absoluteTop(asideElement, top - contentTop);
    }
  } else if (prevScroll > currentScroll) {
    //прокрутка вверх

    if (top > 0 && top < headerHeight + topOffset) {
      /**
       * технически:
       *  - верхняя граница ниже верхней границы экрана
       *  - и верхняя граница выше нижней границы хедера с учетом смещения
       * логически:
       *  - прокрутили до начала страницы
       * реакция:
       *  - фиксируем на текущей позиции чтобы прокручивалось с прокруткой
       */
      return absoluteTop(asideElement, top - contentTop);
    } else if (contentTop >= headerHeight + topOffset) {
      /**
       * технически:
       *  - верхняя граница выше или равна верхней границы контента
       * логически:
       *  - прокрутили контент до уровня элемента
       * реакция:
       *  - сбрасываем позицию в дефолт
       */
      return resetPosition(asideElement);
    } else if (top >= headerHeight) {
      /**
       * технически:
       *  - верхняя граница ниже или равна нижней границы хедера
       * логически:
       *  - прокрутили элемент до начала
       * реакция:
       *  - фиксируем по верхней границе
       */
      return fixTop(asideElement, headerHeight + topOffset);
    } else {
      /**
       * логически:
       *  - никакие граничные случаи не достигнуты
       * реакция:
       *  - фиксируем на текущей позиции чтобы прокручивалось с прокруткой
       */
      return absoluteTop(asideElement, top - contentTop);
    }
  } else {
    //прокрутка не изменилась
  }
  return null;
};
const log = function () {
  if (debug) {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    console.debug('AsideBehavior', ...args);
  }
};
const absoluteBottom = (element, bottom) => {
  log('try absoluteBottom');
  if (element.style.position !== 'absolute') {
    log('absoluteBottom');
    return {
      position: 'absolute',
      top: 'auto',
      bottom: `${bottom}px`
    };
  } else {
    return null;
  }
};
const absoluteTop = (element, top) => {
  log('try absoluteTop');
  if (element.style.position !== 'absolute') {
    log('absoluteTop');
    return {
      position: 'absolute',
      top: `${top}px`,
      bottom: 'auto'
    };
  } else {
    return null;
  }
};
const fixBottom = (element, bottom) => {
  log('try fixBottom');
  if (element.style.position !== 'fixed') {
    log('fixBottom');
    return {
      position: 'fixed',
      top: 'auto',
      bottom: `${bottom}px`
    };
  } else {
    return null;
  }
};
const fixTop = (element, top) => {
  log('try fixTop');
  if (element.style.position !== 'fixed') {
    log('fixTop');
    return {
      position: 'fixed',
      top: `${top}px`,
      bottom: 'auto'
    };
  } else {
    return null;
  }
};
const resetPosition = element => {
  log('try resetPosition');
  if (element.style.position !== 'static') {
    log('resetPosition');
    return {
      position: 'static',
      top: '',
      bottom: ''
    };
  } else {
    return null;
  }
};