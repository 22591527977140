import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import addressApi from 'data/openApi/address';
import { fetchableDefault } from 'data/store/types';
import { EAddressLevel } from 'domain/model/enums';
export const locationDetectorCityFetch = createAsyncThunk('locationDetector/city/fetch', async (props, _ref) => {
  let {
    signal,
    rejectWithValue
  } = _ref;
  try {
    const {
      data
    } = await addressApi.addressByIpQuery({
      signal
    });
    return data;
  } catch (e) {
    console.warn(e.response);
    const {
      data
    } = await addressApi.addressQuery({
      query: 'Москва',
      level: EAddressLevel.City,
      signal
    });
    return rejectWithValue(data?.[0] ?? null);
  }
});
const slice = createSlice({
  name: 'locationDetector',
  initialState: {
    city: {
      ...fetchableDefault,
      data: null
    }
  },
  reducers: {
    locationDetectorClear(state) {
      state.city = {
        ...fetchableDefault,
        data: null
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(locationDetectorCityFetch.pending, state => {
      state.city.isFetching = true;
      state.city.isFetched = false;
      state.city.isFailed = false;
      state.city.data = null;
    }).addCase(locationDetectorCityFetch.fulfilled, (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.city.isFetching = false;
      state.city.isFetched = true;
      state.city.isFailed = false;
      state.city.data = payload;
    }).addCase(locationDetectorCityFetch.rejected, (state, _ref3) => {
      let {
        meta,
        payload
      } = _ref3;
      const {
        aborted
      } = meta;
      if (!aborted) {
        state.city.isFetching = false;
        state.city.isFetched = false;
        state.city.isFailed = true;
      } else {
        state.city.isFetching = false;
        state.city.isFetched = false;
        state.city.isFailed = false;
      }
      state.city.data = payload ?? null;
    });
  }
});
export const {
  locationDetectorClear
} = slice.actions;
export default slice.reducer;