import CodeConfirmationDialog from './dialog';
import { useCodeEdit } from './hooks/useCodeEdit';
import { utcToLocalTimeZone } from '../../../utils/date';
import moment from 'moment-timezone';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const RequiredActionConfirmCodeContainer = _ref => {
  let {
    email,
    source,
    back,
    onSuccess,
    onGetNewCode,
    onClose
  } = _ref;
  const {
    validation,
    codeTtl,
    codeRequestId,
    codeNextAttemptDate,
    isFetching,
    onSendCode
  } = useCodeEdit({
    source,
    onSuccess
  });
  const codeNextAttemptSeconds = utcToLocalTimeZone(codeNextAttemptDate).diff(moment(), 'seconds');
  return _jsx(CodeConfirmationDialog, {
    open: true,
    email: email,
    codeTtl: codeTtl,
    codeRequestId: codeRequestId,
    codeNextAttemptSeconds: codeNextAttemptSeconds,
    validation: validation?.code,
    isFetching: isFetching,
    back: back,
    onGetNewCode: onGetNewCode,
    onClose: onClose,
    onSendCode: onSendCode
  });
};