import { EBalanceType, EUserGender, EUserRole } from 'domain/model/enums';
import { EDateTimeFormat } from 'domain/model/formats';
import { currencySymbols } from 'locale';
import { pluralize } from 'presentation/utils';
import { toCurrency } from 'presentation/utils/currency';
import rootRouting from '../../../routing';
import { EAppFeature } from '../../types';
import { utcToLocalTimeZone } from '../../utils/date';
import { getUserProfileRoute } from './entry';
export const getUserGenderName = gender => {
  switch (gender) {
    case EUserGender.Male:
      return 'Мужской';
    case EUserGender.Female:
      return 'Женский';
  }
};
export const getAllowedRoles = realmRoles => {
  return realmRoles.filter(existed => Object.values(EUserRole).some(localRole => localRole === existed));
};
export const getCustomerAllowedRoles = userRoles => {
  return userRoles.filter(userRole => [EUserRole.Customer, EUserRole.Corp].includes(userRole));
};
export const getUserDefaultRoute = (hasRole, hasFeature) => {
  // Открываем страницу товаров для всех
  if (hasFeature(EAppFeature.Product)) {
    return rootRouting.product;
  }

  // Открываем страницу корпоративных предложений
  if (hasRole(EUserRole.Corp)) {
    return rootRouting.corpOffer;
  }

  // Открываем страницу торговых предложений
  if (hasFeature(EAppFeature.Trade)) {
    return rootRouting.tradeOffer;
  }

  // Если ничего нет то страницу профиля
  return getUserProfileRoute();
};
export const getUserBalanceExpireMessage = (value, type, expireDate) => {
  let typedValue = '';
  switch (type) {
    case EBalanceType.Bonuses:
      typedValue = pluralize(value, ['бонусный балл', 'бонусных балла', 'бонусных баллов']);
      break;
    case EBalanceType.ASP:
      typedValue = currencySymbols.RU;
      break;
  }
  const dateMoment = utcToLocalTimeZone(expireDate);
  const date = dateMoment.year() === new Date().getFullYear() ? dateMoment.format(EDateTimeFormat.HumanPointShort) : dateMoment.format(EDateTimeFormat.HumanPoint);
  return [date, 'сгорит', toCurrency(value), typedValue].filter(item => !!item).join(' ');
};