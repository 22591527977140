import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Typography } from '@mui/material';
import { EOrderByDateType } from 'domain/model/enums';
import { EDateFormat } from 'domain/model/formats';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPButton } from 'presentation/theme/ui-kit/button';
import ConfirmButtons from 'presentation/theme/ui-kit/dialog/buttons';
import { toRubCurrency } from 'presentation/utils/currency';
import AccordionWithIcon from 'presentation/components/common/accordionWithIcon';
import BookingOfferModalPeriod from './calendarPeriod';
import BookingOfferModalMultiple from './calendarMultiple';
import BookingOfferModalNumber from './calendarNumber';
import { PriceWrapper, StyledConfirmDialog } from './controls';
import { DaysHelperText, PeriodHelperText } from './constants';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingOfferModal = _ref => {
  let {
    isVisible,
    index,
    orderItem,
    priceUnit,
    onClose
  } = _ref;
  const {
    name,
    priceItems,
    orderByDateType
  } = priceUnit || {};
  const {
    priceItem,
    qty,
    slots
  } = orderItem || {};
  const [calendarSlots, setCalendarSlots] = useState(slots?.map(_ref2 => {
    let {
      startDate,
      endDate,
      qty
    } = _ref2;
    return {
      qty,
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate()
    };
  }) ?? []);
  const [bookingQty, setBookingQty] = useState(qty || 1);
  const item = priceItems?.find(_ref3 => {
    let {
      id
    } = _ref3;
    return id === priceItem.id;
  });
  useEffect(() => {
    if (isVisible) {
      return;
    }
    setCalendarSlots([]);
    setBookingQty(1);
  }, [isVisible]);
  useEffect(() => {
    if (!qty) {
      return;
    }
    setBookingQty(qty);
  }, [qty]);
  useEffect(() => {
    if (!slots) {
      return;
    }
    setCalendarSlots(slots);
  }, [slots]);
  if (!isVisible || !item) {
    return null;
  }
  const handleSubmit = () => {
    onClose({
      index,
      priceUnit,
      orderItem: {
        priceItem,
        qty: bookingQty,
        slots: calendarSlots.map(_ref4 => {
          let {
            startDate,
            endDate,
            qty
          } = _ref4;
          return {
            qty,
            startDate: moment(startDate).format(EDateFormat.Server),
            endDate: moment(endDate).format(EDateFormat.Server)
          };
        })
      }
    });
  };
  const {
    name: itemName,
    price,
    unit
  } = item;
  const isPeriod = orderByDateType === EOrderByDateType.Period;
  const hasCalendar = orderByDateType !== EOrderByDateType.None;
  const hasSelection = hasCalendar && (isPeriod && calendarSlots?.[0]?.startDate && calendarSlots?.[0]?.endDate || !isPeriod && calendarSlots?.length) || qty && qty > 0;
  return _jsxs(StyledConfirmDialog, {
    title: name,
    open: isVisible,
    maxWidth: "xs",
    fullScreenBreakpoint: "sm",
    onClose: () => onClose(),
    buttons: _jsxs(ConfirmButtons, {
      children: [_jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        onClick: handleSubmit,
        disabled: !hasSelection,
        children: typeof index === 'undefined' ? 'Добавить' : 'Изменить'
      }), _jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        variant: "text",
        size: "large",
        onClick: () => onClose(),
        children: "\u041E\u0442\u043C\u0435\u043D\u0430"
      })]
    }),
    children: [_jsxs(MPGrid, {
      container: true,
      spacing: 1,
      justifyContent: "space-between",
      mb: 1,
      flexWrap: "nowrap",
      children: [_jsx(MPGrid, {
        item: true,
        children: itemName
      }), _jsx(MPGrid, {
        item: true,
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        children: _jsxs(PriceWrapper, {
          children: [_jsx(Typography, {
            variant: "subtitle1",
            mr: 0.5,
            children: toRubCurrency(price)
          }), _jsx("span", {
            children: unit?.name?.toLocaleLowerCase() ?? ''
          })]
        })
      })]
    }), hasCalendar && _jsxs(_Fragment, {
      children: [_jsx(AccordionWithIcon, {
        title: `Выбор ${isPeriod ? 'периода' : 'по датам'}`,
        description: isPeriod ? PeriodHelperText : DaysHelperText
      }), isPeriod && _jsx(BookingOfferModalPeriod, {
        slots: calendarSlots,
        index: index?.[1] ?? 0,
        setSlots: setCalendarSlots
      }), !isPeriod && _jsx(BookingOfferModalMultiple, {
        slots: calendarSlots,
        setSlots: setCalendarSlots
      })]
    }), !hasCalendar && _jsx(BookingOfferModalNumber, {
      count: bookingQty,
      onChange: setBookingQty
    })]
  });
};
export default BookingOfferModal;