import { Typography } from '@mui/material';
import { toRubCurrency } from '../../../../../utils/currency';
import Splitter from '../../../../../components/common/splitter';
import { MPButton, MPFab } from '../../../../../theme/ui-kit/button';
import { ReasonHeader, ReasonIcon, ReasonWrapper } from './controls';
import { BalancesIcon, CalendarIcon, FavoriteOutlineIcon } from '../../../../../media/icons';
import moment from 'moment-timezone';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspBanner = _ref => {
  let {
    bonusSize,
    year,
    deactivateTimestamp,
    onAccept
  } = _ref;
  const activeBeforeTimestamp = moment(deactivateTimestamp).add(-1, 'minutes');
  return _jsxs("div", {
    children: [_jsxs(Typography, {
      variant: "h3",
      children: ["\u0412\u044B\u043F\u043B\u0430\u0442\u0430 ", toRubCurrency(bonusSize), " \u0434\u043B\u044F \u0432\u0430\u0441 \u043D\u0430 \u0441\u0430\u043C\u043E\u0435 \u0432\u0430\u0436\u043D\u043E\u0435"]
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsxs("div", {
      children: [_jsxs(ReasonWrapper, {
        children: [_jsx(ReasonIcon, {
          children: _jsx(MPFab, {
            variant: 'squared',
            size: 'medium',
            color: 'secondaryLight',
            disableRipple: true,
            children: _jsx(FavoriteOutlineIcon, {})
          })
        }), _jsxs("div", {
          children: [_jsx(ReasonHeader, {
            variant: "subtitle1",
            children: "\u0412\u044B \u2014 \u0446\u0435\u043D\u043D\u044B\u0439 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A"
          }), _jsxs(Typography, {
            variant: "body2",
            children: ["\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u044F \u0420\u0416\u0414 \u043F\u0440\u0435\u0434\u043B\u0430\u0433\u0430\u0435\u0442 \u0432\u0430\u043C ", toRubCurrency(bonusSize), ". \u042D\u0442\u0438 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u0430 \u043C\u043E\u0436\u043D\u043E \u043F\u043E\u0442\u0440\u0430\u0442\u0438\u0442\u044C \u0432 ", year, " \u0433\u043E\u0434\u0443 \u043D\u0430 \u0412\u0438\u0442\u0440\u0438\u043D\u0435 \u043D\u0430 \u0440\u0430\u0437\u043B\u0438\u0447\u043D\u044B\u0435 \u0443\u0441\u043B\u0443\u0433\u0438: \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u0435, \u0437\u0434\u043E\u0440\u043E\u0432\u044C\u0435, \u043E\u0431\u0443\u0447\u0435\u043D\u0438\u0435 \u0438 \u043C\u043D\u043E\u0433\u043E\u0435 \u0434\u0440\u0443\u0433\u043E\u0435."]
          })]
        })]
      }), _jsxs(ReasonWrapper, {
        children: [_jsx(ReasonIcon, {
          children: _jsx(MPFab, {
            variant: 'squared',
            size: 'medium',
            color: 'secondaryLight',
            disableRipple: true,
            children: _jsx(CalendarIcon, {})
          })
        }), _jsxs("div", {
          children: [_jsx(ReasonHeader, {
            variant: "subtitle1",
            children: "\u042D\u0442\u043E \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E \u0438 \u0431\u0435\u0437 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u0441\u0442\u0432"
          }), _jsxs(Typography, {
            variant: "body2",
            children: ["\u041F\u0440\u0438\u043D\u044F\u0442\u044C \u0440\u0435\u0448\u0435\u043D\u0438\u0435 \u043C\u043E\u0436\u043D\u043E \u0434\u043E ", activeBeforeTimestamp.format('D MMMM YYYY года, HH:mm'), ". \u0415\u0441\u043B\u0438 \u0432\u044B \u043E\u0442\u043A\u0430\u0436\u0435\u0442\u0435\u0441\u044C, \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u0430 \u0431\u0443\u0434\u0443\u0442 \u043F\u0435\u0440\u0435\u0434\u0430\u043D\u044B \u0442\u0435\u043C, \u043A\u0442\u043E \u0432 \u043D\u0438\u0445 \u043D\u0443\u0436\u0434\u0430\u0435\u0442\u0441\u044F."]
          })]
        })]
      }), _jsxs(ReasonWrapper, {
        children: [_jsx(ReasonIcon, {
          children: _jsx(MPFab, {
            variant: 'squared',
            size: 'medium',
            color: 'secondaryLight',
            disableRipple: true,
            children: _jsx(BalancesIcon, {})
          })
        }), _jsxs("div", {
          children: [_jsx(ReasonHeader, {
            variant: "subtitle1",
            children: "\u0414\u0440\u0443\u0433\u0438\u0435 \u0432\u044B\u043F\u043B\u0430\u0442\u044B"
          }), _jsx(Typography, {
            variant: "body2",
            children: "\u0415\u0441\u043B\u0438 \u0432\u044B \u043F\u043E\u043B\u0443\u0447\u0430\u0435\u0442\u0435 \u0434\u0440\u0443\u0433\u0438\u0435 \u0433\u043E\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u0435 \u043B\u044C\u0433\u043E\u0442\u044B, \u043E\u043D\u0438 \u043C\u043E\u0433\u0443\u0442 \u0431\u044B\u0442\u044C \u043F\u0440\u0438\u043E\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u044B \u043F\u0440\u0438 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0438 \u044D\u0442\u043E\u0439 \u0432\u044B\u043F\u043B\u0430\u0442\u044B, \u043F\u043E\u0442\u043E\u043C\u0443 \u0447\u0442\u043E \u043E\u043D\u0430 \u0441\u0447\u0438\u0442\u0430\u0435\u0442\u0441\u044F \u0434\u043E\u0445\u043E\u0434\u043E\u043C."
          })]
        })]
      }), _jsxs("div", {
        children: [_jsx(MPButton, {
          fullWidth: true,
          size: "small",
          color: "brand",
          variant: "contained",
          onClick: onAccept,
          children: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0432\u044B\u043F\u043B\u0430\u0442\u0443"
        }), _jsx(Splitter, {
          size: 1
        }), _jsx(MPButton, {
          fullWidth: true,
          size: "small",
          color: "brand",
          variant: "text",
          children: "\u0423\u0437\u043D\u0430\u0442\u044C \u0434\u0435\u0442\u0430\u043B\u0438"
        })]
      })]
    })]
  });
};
export default AspBanner;