import { EPartnerType } from 'domain/model/enums';
import { LogoImageView } from 'presentation/components/common/images/cover';
import { innerPartnerLogoStub } from 'presentation/features/partnerDesk/utils';
import { LogoWrapper } from '../../../components/listItem';
import { CardCaption } from '../../../components/listItem/controls';
import { InfoWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferListItemInfo = props => {
  const {
    partner: {
      logo,
      type,
      name: partnerName
    }
  } = props;
  const logoPath = !logo && type === EPartnerType.InnerPartner ? innerPartnerLogoStub : logo?.path;
  return _jsxs(InfoWrapper, {
    children: [_jsx(CardCaption, {
      variant: "body2",
      color: "textSecondary",
      children: partnerName
    }), logoPath && _jsx(LogoWrapper, {
      children: _jsx(LogoImageView, {
        id: logoPath
      })
    })]
  });
};
export default CorpOfferListItemInfo;