import { useGetCorpOfferDetailsQuery } from 'data/api/corpOffer';
import { EBalanceType, EOfferType } from 'domain/model/enums';
import moment from 'moment-timezone';
import { getUserProfileRoute } from 'presentation/features/user/entry';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AppConfigurator } from 'system/appConfigurator';
import useCurrentUserBalance from '../../../../../hooks/useCurrentUserBalance';
import useOfferActivations from '../../../hooks/useOfferActivations';
import useOfferSubscription from '../../../hooks/useOfferSubscription';
import CorpOfferClaimContext from './context';
import useTakeOfferActivations from '../../../hooks/useTakeOfferActivations';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferClaimProvider = props => {
  const {
    children,
    id,
    promotionType
  } = props;
  const history = useHistory();
  const {
    data: corpOffer = null,
    refetch: corpOfferRefetch
  } = useGetCorpOfferDetailsQuery({
    id
  }, {
    refetchOnMountOrArgChange: true
  });
  const activation = useOfferActivations({
    offerId: id,
    offerPrice: corpOffer?.price,
    offerRefetch: corpOfferRefetch,
    balanceType: EBalanceType.Bonuses
  });
  const {
    isUserBalanceNotEnough,
    isLoadActivationsFetching,
    lastReceivedActivation
  } = activation;
  const {
    onTakeActivation,
    isTaking,
    onCopy,
    isTaked,
    onNeedCopy,
    needCopy,
    analyzeOfferActivation
  } = useTakeOfferActivations({
    lastActivation: lastReceivedActivation
  });
  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    isSupported: isSubscriptionSupported,
    onSubscribe,
    onUnSubscribe
  } = useOfferSubscription({
    offer: corpOffer,
    offerType: EOfferType.Corp,
    promotionType
  });
  const {
    value: userBalance,
    isFetching: userBalanceIsFetching,
    refreshBalance
  } = useCurrentUserBalance();
  const earnPointsUrl = AppConfigurator.getInstance().getCorpEarningsSystemUrl() ?? '';
  const onActivate = useCallback(async () => {
    if (!corpOffer) {
      return null;
    }
    analyzeOfferActivation(corpOffer);
    const result = await activation.activateOffer(corpOffer);
    if (result) {
      onTakeActivation(result);
    }
    return result;
  }, [corpOffer, analyzeOfferActivation, activation, onTakeActivation]);
  const onFillProfile = useCallback(() => {
    history.push(getUserProfileRoute());
  }, [history]);
  useEffect(() => {
    if (isUserBalanceNotEnough) {
      refreshBalance();
    }
  }, [isUserBalanceNotEnough, refreshBalance]);
  const isFetching = isLoadActivationsFetching || isUserBalanceNotEnough && userBalanceIsFetching;
  const endDate = corpOffer ? moment(corpOffer.endDate).endOf('day') : null;
  const isActivationReceivedNow = !!lastReceivedActivation;
  const value = {
    corpOffer,
    endDate,
    isFetching,
    earnPointsUrl,
    userBalance,
    activation,
    isSubscriptionEnabled,
    isSubscriptionFetching,
    isSubscriptionSupported,
    isActivationReceivedNow,
    onActivate,
    onSubscribe,
    onUnSubscribe,
    onFillProfile,
    onTakeActivation,
    onCopy,
    isTaked,
    needCopy,
    onNeedCopy,
    isTaking
  };
  return _jsx(CorpOfferClaimContext.Provider, {
    value: value,
    children: children
  });
};
export default CorpOfferClaimProvider;