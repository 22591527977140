import moment from 'moment-timezone';
import { isValidPhoneFormat } from 'presentation/utils/phone';
import validator from 'validator';
import { EValidationType, validationRegexp } from '../../utils/validation';
export const userProfileValidation = {
  lastName: {
    required: true
  },
  firstName: {
    required: true
  },
  locality: {
    required: true
  },
  gender: {
    required: true,
    requiredMessage: 'Выберите пол'
  },
  birthDate: {
    required: true,
    validator: (object, value) => {
      if (!value) return null;
      if (!moment(value).isValid()) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Необходимо указать корректную дату'
        };
      }
      if (moment(value).isBefore(moment('1900-01-01'))) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Необходимо указать корректную дату'
        };
      }
      const age = moment().startOf('day').diff(moment(value).endOf('day'), 'year');
      if (age < 18) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Указана некорректная дата рождения. Минимальный возраст должен быть 18 лет от текущей даты.'
        };
      }
      return null;
    }
  },
  email: {
    validator: (object, value) => {
      if (!value) return null;
      const isEmail = validator.isEmail(value);
      if (!isEmail || !validationRegexp.email.test(value)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Необходимо указать корректный адрес электронной почты'
        };
      } else {
        return null;
      }
    }
  },
  phone: {
    validator: (object, value) => {
      if (!value) return null;
      const isValid = isValidPhoneFormat(value);
      if (!isValid) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Введите корректный номер телефона'
        };
      } else {
        return null;
      }
    }
  }
};