import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useHistory } from 'react-router';
import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import { getUserActivationsRoute } from '../../../user/entry';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferActivateDialog = _ref => {
  let {
    open,
    title,
    description,
    onClose
  } = _ref;
  const history = useHistory();
  const openPromotions = () => {
    onClose();
    history.push(getUserActivationsRoute({}));
  };
  return _jsx(MPConfirmDialog, {
    open: open,
    maxWidth: "xs",
    title: title,
    onClose: onClose,
    buttons: _jsx(MPButton, {
      color: "brand",
      size: "large",
      fullWidth: true,
      onClick: openPromotions,
      children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0432 \u041C\u043E\u0438\u0445 \u043F\u0440\u0438\u0432\u0438\u043B\u0435\u0433\u0438\u044F\u0445"
    }),
    children: _jsx(Typography, {
      variant: "body2",
      dangerouslySetInnerHTML: {
        __html: description
      }
    })
  });
};
export default CorpOfferActivateDialog;