import ActivationListItem from '../../../../activation/listItem';
import useTakeOfferActivations from '../../../../offer/hooks/useTakeOfferActivations';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ActivationListItemAdapter = props => {
  const {
    activation,
    getActivationCodeTypeName,
    onOpenHelpDialog,
    onPartnerLinkClick,
    onOfferClick
  } = props;
  const {
    onTakeActivation,
    isTaking,
    onNeedCopy,
    needCopy,
    isTaked
  } = useTakeOfferActivations({
    lastActivation: activation
  });
  return _jsx(ActivationListItem, {
    activation: activation,
    onTakeActivation: () => onTakeActivation(activation),
    getActivationCodeTypeName: getActivationCodeTypeName,
    onOfferClick: onOfferClick,
    onPartnerLinkClick: onPartnerLinkClick,
    onOpenHelpDialog: onOpenHelpDialog,
    onNeedCopy: onNeedCopy,
    needCopy: needCopy,
    copied: isTaked,
    fileDownloading: isTaking
  });
};
export default ActivationListItemAdapter;