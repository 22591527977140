import apiDefinition from '../openApi';
import { api, EAgreementServicesTag } from './index';
export const agreementsApi = api.injectEndpoints({
  endpoints: builder => ({
    checkUserCurrentFetch: builder.query({
      keepUnusedDataFor: 0,
      transformResponse: response => response.data,
      query: () => apiDefinition.user.current({}),
      providesTags: [EAgreementServicesTag.Check]
    }),
    getUserAgreements: builder.query({
      keepUnusedDataFor: 0,
      query: () => apiDefinition.user.agreement.all({}),
      transformResponse: response => {
        console.log('Call getUserAgreements');
        const agreements = response.data;
        const isAllSigned = agreements.every(a => a.agreementDate);
        if (isAllSigned) {
          return agreements;
        }
        return agreements.filter(a => !a.agreementDate);
      }
    }),
    signUserAgreements: builder.mutation({
      query: _ref => {
        let {
          data
        } = _ref;
        return apiDefinition.user.agreement.sign({
          data
        });
      },
      transformResponse: response => response.data,
      invalidatesTags: [EAgreementServicesTag.Check],
      // eslint-disable-next-line no-empty-pattern
      onQueryStarted: async (_ref2, _ref3) => {
        let {} = _ref2;
        let {
          queryFulfilled,
          dispatch
        } = _ref3;
        try {
          await queryFulfilled;
          dispatch(agreementsApi.util.upsertQueryData('getUserAgreements', {}, []));
        } catch {
          console.error('Pessimistic update of agreements is failed');
        }
      }
    })
  })
});
export const {
  useCheckUserCurrentFetchQuery,
  useSignUserAgreementsMutation,
  useGetUserAgreementsQuery,
  useLazyGetUserAgreementsQuery
} = agreementsApi;