import Splitter from 'presentation/components/common/splitter';
import useUserLocalSettings from 'presentation/hooks/useUserLocalSettings';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const OnboardingCookieAcceptAdapter = _ref => {
  let {
    onNext
  } = _ref;
  const {
    setUserCookieAccepted
  } = useUserLocalSettings();
  const onAccept = () => {
    setUserCookieAccepted(true);
    onNext();
  };
  return _jsxs(_Fragment, {
    children: ["\u041C\u044B \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u043C cookies, \u0447\u0442\u043E\u0431\u044B \u0441\u0430\u0439\u0442 \u0440\u0430\u0431\u043E\u0442\u0430\u043B \u043B\u0443\u0447\u0448\u0435", _jsx(Splitter, {
      size: 1
    }), _jsx(MPButton, {
      size: "small",
      color: "brand",
      variant: "contained",
      onClick: onAccept,
      children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
    })]
  });
};