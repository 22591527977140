import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmButtons from '../../../../theme/ui-kit/dialog/buttons';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserProfileEditAttribute = props => {
  const {
    label,
    isFetching,
    onClose,
    onSave,
    children,
    primaryText = 'Сохранить'
  } = props;
  return _jsx(MPConfirmDialog, {
    open: true,
    title: label,
    maxWidth: "xs",
    fullScreenBreakpoint: "sm",
    scroll: "body",
    buttons: _jsxs(ConfirmButtons, {
      children: [_jsxs(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        disabled: isFetching,
        onClick: onSave,
        children: [primaryText, isFetching && _jsx(ContentLoader, {})]
      }), _jsx(MPButton, {
        color: "brand",
        size: "large",
        variant: "text",
        fullWidth: true,
        disabled: isFetching,
        onClick: onClose,
        children: "\u041E\u0442\u043C\u0435\u043D\u0430"
      })]
    }),
    onClose: onClose,
    children: children
  });
};