import apiDefinition from 'data/openApi';
import { ECorpOfferPromotionType, ECorpOfferSortType, EOfferActivateError, EOfferActivationStatus, EOfferClientTagType, EOfferType, EProductOfferSortType, ETradeOfferPromotionType, ETradeOfferSortType } from 'domain/model/enums';
import { pluralize } from '../../utils';
export const offerDefaultSearchCount = 6;
export const offerSearchQueryMinLength = 3;
export const offerImageAspectRatio = 1.46; //завязано на аспекте загружаемых картинок в админке

export const aspOfferTag = {
  type: EOfferClientTagType.ASP,
  name: 'Доступно с АСП'
};
export const getOfferNotUsedCountText = (count, type) => {
  const threshold = 99;
  const displayCount = count > threshold ? threshold : count;
  const text = count > threshold ? `более ${displayCount}` : displayCount.toString();
  try {
    switch (type) {
      case ETradeOfferPromotionType.AccessCode:
        return `${text} ${pluralize(displayCount, ['код доступа', 'кода доступа', 'кодов доступа'])}`;
      case ETradeOfferPromotionType.Promocode:
        return `${text} ${pluralize(displayCount, ['промокод', 'промокода', 'промокодов'])}`;
      case ETradeOfferPromotionType.Voucher:
        return `${text} ${pluralize(displayCount, ['ваучер', 'ваучера', 'ваучеров'])}`;
      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
      case ECorpOfferPromotionType.Certificate:
      case ECorpOfferPromotionType.ExternalCertificate:
        return `${text} ${pluralize(displayCount, ['сертификат', 'сертификата', 'сертификатов'])}`;
    }
  } catch (e) {
    console.error(`Unknown promotion type ${type}`);
  }
  return '';
};
export const getOfferCodesEndsText = type => {
  try {
    switch (type) {
      case ETradeOfferPromotionType.AccessCode:
        return `Доступных кодов доступа не осталось`;
      case ETradeOfferPromotionType.Promocode:
        return `Доступных промокодов не осталось`;
      case ETradeOfferPromotionType.Voucher:
        return `Доступных ваучеров не осталось`;
      case ETradeOfferPromotionType.Digift:
      case ETradeOfferPromotionType.Asp:
      case ECorpOfferPromotionType.Certificate:
      case ECorpOfferPromotionType.ExternalCertificate:
        return `Доступных сертификатов не осталось`;
    }
  } catch (e) {
    console.error(`Unknown promotion type ${type}`);
  }
  return '';
};

//todo asp сделать одну функцию getOfferPromotionLabel для corp+trade+asp
export const getTradeOfferPromotionLabel = type => {
  try {
    switch (type) {
      case ETradeOfferPromotionType.AccessCode:
        return 'Код доступа';
      case ETradeOfferPromotionType.Promocode:
        return 'Промокод';
      case ETradeOfferPromotionType.Voucher:
        return 'Ваучер';
      case ETradeOfferPromotionType.Digift:
      case ETradeOfferPromotionType.Asp:
        return 'Сертификат';
      case ETradeOfferPromotionType.ReferralLink:
        return 'Реферальная ссылка';
      case ETradeOfferPromotionType.PublicPromocode:
        return 'Промокод';
      case ETradeOfferPromotionType.Widget:
        return 'Виджет';
    }
  } catch (e) {
    console.error(`Unknown promotion type ${type}`);
  }
  return '';
};
export const getCorpOfferPromotionLabel = type => {
  try {
    switch (type) {
      case ECorpOfferPromotionType.Certificate:
        return 'Сертификат';
      case ECorpOfferPromotionType.ExternalCertificate:
        return 'Внешний сертификат';
    }
  } catch (e) {
    console.error(`Unknown promotion type ${type}`);
  }
  return '';
};
export const getPersonalPromotionInstrumentalLabel = type => {
  try {
    switch (type) {
      case ETradeOfferPromotionType.AccessCode:
        return 'кода доступа';
      case ETradeOfferPromotionType.Promocode:
        return 'промокода';
      case ETradeOfferPromotionType.Voucher:
        return 'ваучера';
      case ECorpOfferPromotionType.Certificate:
        return 'предложения';
      case ETradeOfferPromotionType.Digift:
      case ETradeOfferPromotionType.Asp:
      case ECorpOfferPromotionType.ExternalCertificate:
        return 'сертификата';
    }
  } catch (e) {
    console.error(`Unknown promotion type ${type}`);
  }
  return '';
};
export const getOfferLastActivation = function (activations) {
  let includeRejected = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return activations?.filter(activation => includeRejected || activation.status !== EOfferActivationStatus.Rejected)?.sort((p1, p2) => new Date(p2.createdAt).getTime() - new Date(p1.createdAt).getTime())?.[0] ?? null;
};
export const getOfferPromotionDownloadUrl = _ref => {
  let {
    id,
    promotion
  } = _ref;
  const {
    type
  } = promotion;
  switch (type) {
    case ETradeOfferPromotionType.Voucher:
    case ETradeOfferPromotionType.Asp:
    case ETradeOfferPromotionType.Digift:
    case ECorpOfferPromotionType.Certificate:
      return apiDefinition.activation.certificateDownloadUrl({
        activationId: id
      });
    default:
      return null;
  }
};
export const getOfferActivationErrorText = error => {
  const {
    type,
    message
  } = error;
  try {
    switch (type) {
      case EOfferActivateError.PromotionDayCountLimit:
        return message ?? 'На сегодня лимит исчерпан. Попробуйте завтра';
      case EOfferActivateError.InvalidOfferStatus:
        return 'Предложение не доступно для создания активации из-за своего статуса';
      case EOfferActivateError.PromotionFreshOut:
        return 'В предложении нет свободных персональных кодов';
      case EOfferActivateError.OfferActivationAlreadyExist:
        return 'Предложение уже было активировано, последующие активации недоступны для данного предложения';
      case EOfferActivateError.InappropriateTargeting:
        return 'Предложение не доступно для данного пользователя по таргетингу';
      case EOfferActivateError.PromotionTimeLimit:
        return message ?? 'С момента получения последнего промокода не прошло достаточно времени';
      case EOfferActivateError.InvalidUserStatus:
        return message ?? 'Профиль пользователя не заполнен';
      case null:
        return message || 'Что-то пошло не так и мы не смогли дать новый промокод. Попробуйте ещё раз позже';
    }
  } catch (e) {
    console.error(e);
    return 'Неизвестная ошибка';
  }
};
export const getOffersListQueryParams = (props, offerType) => {
  const {
    name,
    categories,
    pageSize,
    page = 1,
    sort = null,
    statuses,
    partnerId
  } = props;

  /**
   * если есть поиск по name и не выбран вручную sort - отдает сортировку на откуп серверу
   * если нет поиска по name и не выбран вручную sort - сортируем по дефолтному параметру
   */
  let sortOut = sort;
  if (name && (!sort || sort.length === 0)) {
    sortOut = null;
  }
  if (!name && (!sort || sort.length === 0)) {
    if (offerType === EOfferType.Product) {
      sortOut = [EProductOfferSortType.ByNovelty];
    } else if (offerType === EOfferType.Corp) {
      sortOut = [ECorpOfferSortType.Default];
    } else {
      sortOut = [ETradeOfferSortType.Default];
    }
  }
  return {
    search: {
      name,
      categories,
      statuses,
      partnerId
    },
    page,
    pageSize,
    sort: sortOut
  };
};
export const getOffersCountQueryParams = props => {
  const {
    name,
    categories
  } = props;
  return {
    search: {
      name,
      categories
    }
  };
};
export const isOfferReceiptDateSupportedByPromotionType = promotionType => {
  switch (promotionType) {
    case ETradeOfferPromotionType.Promocode:
    case ETradeOfferPromotionType.Voucher:
    case ETradeOfferPromotionType.AccessCode:
    case ETradeOfferPromotionType.PublicPromocode:
      return true;
    default:
      return false;
  }
};
export const isOfferSubscriptionSupportedByPromotionType = promotionType => {
  switch (promotionType) {
    case ETradeOfferPromotionType.Promocode:
    case ETradeOfferPromotionType.Voucher:
    case ETradeOfferPromotionType.AccessCode:
    case ECorpOfferPromotionType.Certificate:
      return true;
    default:
      return false;
  }
};
export const getCategoriesId = categories => categories.map(c => c.id);
export const getCategoryIntersectionById = function () {
  let categories = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let ids = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return categories.filter(c => ids.includes(c.id)).sort((c1, c2) => c1.name.localeCompare(c2.name));
};