import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OnboardingStepLayout = _ref => {
  let {
    children
  } = _ref;
  return _jsx(Wrapper, {
    children: children
  });
};
export default OnboardingStepLayout;