import { Button, Typography } from '@mui/material';
import { toRubCurrency } from 'presentation/utils/currency';
import CountInput from 'presentation/components/common/countInput';
import { Ellipsis } from 'presentation/components/common/ellipsis';
import { Content, Price, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ServiceSelect = _ref => {
  let {
    name,
    price,
    unit,
    count,
    onChange
  } = _ref;
  const handleIncrement = changedCount => {
    onChange(true, changedCount);
  };
  const handleDecrement = changedCount => {
    onChange(false, changedCount);
  };
  return _jsxs(Wrapper, {
    children: [name && _jsx(Typography, {
      component: 'h3',
      variant: 'body1',
      children: name
    }), _jsxs(Content, {
      children: [Number(price) > 0 && _jsxs(Price, {
        children: [_jsx(Typography, {
          variant: 'subtitle1',
          component: 'span',
          children: toRubCurrency(price)
        }), ' ', _jsx(Ellipsis, {
          as: "span",
          children: unit?.name?.toLocaleLowerCase() ?? ''
        })]
      }), _jsx("div", {
        children: count ? _jsx(CountInput, {
          isInputDisabled: true,
          preserveValueOnChanges: true,
          value: count,
          size: 'micro',
          onDecrement: handleDecrement,
          onIncrement: handleIncrement
        }) : _jsx(Button, {
          color: 'brand',
          size: 'small',
          fullWidth: true,
          onClick: () => onChange(),
          children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"
        })
      })]
    })]
  });
};