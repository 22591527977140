import { EOfferActivateError } from 'domain/model/enums';
import UserNeedFillProfileDialog from 'presentation/features/user/components/needFillProfileDialog';
import { useEffect, useState } from 'react';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import OfferNdflHelpDialog from '../../../components/dialogs/ndfl';
import OfferUnavailableDialog from '../../../components/dialogs/unavailable';
import AspOfferHelpDialog from '../../components/dialogs/help';
import useAspOfferClaimContext from '../provider/useAspOfferClaimContext';
import { EAspOfferClaimDialogTag } from '../types';
import AspOfferClaimDialogsContext from './context';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AspOfferClaimDialogsProvider = props => {
  const {
    children,
    aspOffer,
    onShowAllOffers
  } = props;
  const {
    activation,
    onFillProfile
  } = useAspOfferClaimContext();
  const [profileDialogState, setProfileDialogState] = useState();
  const {
    open: isHelpDialogOpened,
    onOpen: onOpenHelpDialog,
    onClose: onCloseHelpDialog
  } = useDialogInHistory({
    tag: EAspOfferClaimDialogTag.Help
  });
  const {
    open: isUnavailableDialogOpened,
    onOpen: onOpenUnavailableDialog,
    onClose: onCloseUnavailableDialog
  } = useDialogInHistory({
    tag: EAspOfferClaimDialogTag.Unavailable
  });
  const {
    open: isNdflDialogOpened,
    onOpen: onOpenNdflDialog,
    onClose: onCloseNdflDialog
  } = useDialogInHistory({
    tag: EAspOfferClaimDialogTag.Ndfl
  });
  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.InvalidUserStatus) {
      setProfileDialogState({
        message: activation.activationError.message
      });
    }
  }, [activation.activationError, setProfileDialogState]);
  const value = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openHelpDialog: onOpenHelpDialog,
    openNdflDialog: onOpenNdflDialog,
    onShowAllOffers
  };
  return _jsxs(AspOfferClaimDialogsContext.Provider, {
    value: value,
    children: [children, _jsx(OfferNdflHelpDialog, {
      open: isNdflDialogOpened,
      description: 'Текст про НДФЛ',
      onClose: onCloseNdflDialog
    }), _jsx(OfferUnavailableDialog, {
      open: isUnavailableDialogOpened,
      onShowOtherOffers: onShowAllOffers,
      onClose: onCloseUnavailableDialog
    }), _jsx(AspOfferHelpDialog, {
      promotionType: aspOffer.promotionType,
      open: isHelpDialogOpened,
      onClose: onCloseHelpDialog
    }), _jsx(UserNeedFillProfileDialog, {
      open: !!profileDialogState,
      message: profileDialogState?.message,
      onFill: onFillProfile,
      onClose: () => setProfileDialogState(null)
    })]
  });
};
export default AspOfferClaimDialogsProvider;