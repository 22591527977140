import { Link } from '@mui/material';
import { EOfferStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import CorpLinkProxy from 'presentation/components/common/links/proxy';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useMemo } from 'react';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferClaimPartnerLink = _ref => {
  let {
    link,
    onLinkClick
  } = _ref;
  return _jsx(CorpLinkProxy, {
    link: link,
    children: linkByProxy => _jsx(MPButton, {
      component: Link,
      size: "large",
      href: linkByProxy,
      fullWidth: true,
      color: "brand",
      target: "_blank",
      onClick: onLinkClick,
      children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0441\u0430\u0439\u0442 \u043F\u0430\u0440\u0442\u043D\u0451\u0440\u0430"
    })
  });
};
export const TradeOfferClaimPartnerLinkAdapter = () => {
  const {
    tradeOffer,
    isActivationReceivedNow,
    onPartnerButtonClick
  } = useTradeOfferClaimContext();
  return useMemo(() => {
    const displayPartnerLink = tradeOffer?.partnerLink && (tradeOffer?.status === EOfferStatus.Active || isActivationReceivedNow) && tradeOffer?.promotionType !== ETradeOfferPromotionType.ReferralLink;
    return displayPartnerLink ? _jsx(TradeOfferClaimPartnerLink, {
      link: tradeOffer.partnerLink,
      onLinkClick: onPartnerButtonClick
    }) : null;
  }, [tradeOffer?.partnerLink, tradeOffer?.status, tradeOffer?.promotionType, isActivationReceivedNow, onPartnerButtonClick]);
};