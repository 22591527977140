import { useState } from 'react';
import { MailIcon } from '../../../../../media/icons';
import { ChangeEmailContainer } from '../../../../auth/changeEmail/container';
import UserProfileViewAttribute from '../../../components/attributeView';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const label = 'Электронная почта';
export const UserProfileEmailAdapter = _ref => {
  let {
    user
  } = _ref;
  const [editMode, setEditMode] = useState(false);
  const hideEdit = () => setEditMode(false);
  const showEdit = () => setEditMode(true);
  return _jsxs(_Fragment, {
    children: [_jsx(UserProfileViewAttribute, {
      label: label,
      value: user.email,
      icon: _jsx(MailIcon, {
        color: "secondary"
      }),
      onTryChange: showEdit
    }), editMode && _jsx(ChangeEmailContainer, {
      onClose: hideEdit
    })]
  });
};