import { Button } from '@mui/material';
import { cacheKeys } from 'data/api';
import { useCreateUserOrdersMutation } from 'data/api/user';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getProductOfferDetailsRoute, getProductOfferListRoute } from '../../offer/product/entry';
import { getUserOrdersRoute } from '../../user/entry';
import { CartOrderListItem } from '../components/cartOrderListItem';
import { getCartRootRoute } from '../entry';
import LastOrdersLayout from './layout';
import { EOrderDiscriminator } from '../../../../domain/model/enums';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = LastOrdersLayout;
const LastOrdersContainer = () => {
  const history = useHistory();
  const [, {
    data,
    reset
  }] = useCreateUserOrdersMutation(cacheKeys.order);
  const onOpenProductDetails = order => {
    // по ТЗ открывать нужно в новом табе
    window.open(getProductOfferDetailsRoute({
      id: order.offer.id
    }));
  };
  const onContinueBuying = () => {
    history.push(getProductOfferListRoute());
  };
  const onRedirectToOrders = () => {
    history.push(getUserOrdersRoute({}));
  };
  useEffect(() => {
    if (!data) history.push(getCartRootRoute());
  }, [data, history]);
  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);
  const isManyOrders = data && data.length > 1;
  const headerText = isManyOrders ? 'Заказы оформлены' : 'Заказ оформлен';
  return _jsx(Layout, {
    title: data && headerText,
    description: data && `${headerText} успешно. Дождитесь звонка от менеджера партнёра для согласования деталей доставки и оплаты.`,
    buttons: data && _jsxs(_Fragment, {
      children: [_jsx(Button, {
        variant: "contained",
        onClick: onContinueBuying,
        children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C \u043F\u043E\u043A\u0443\u043F\u043A\u0438"
      }), _jsx(Button, {
        variant: "outlined",
        onClick: onRedirectToOrders,
        children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0437\u0430\u043A\u0430\u0437\u0430\u043C"
      })]
    }),
    children: data?.map(order => {
      if (order.discriminator !== EOrderDiscriminator.ProductOrder) {
        return null;
      }
      return _jsx(CartOrderListItem, {
        order: order,
        onOpenProductDetails: onOpenProductDetails
      }, order.id);
    })
  });
};
export default LastOrdersContainer;