import { ActionWrapper, ContentWrapper, IconWrapper, TextWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserAttributeLayout = props => {
  const {
    icon,
    label,
    action,
    children
  } = props;
  const actionNode = action && _jsx(ActionWrapper, {
    children: action
  });
  return _jsx(Wrapper, {
    children: _jsxs(ContentWrapper, {
      children: [_jsx(IconWrapper, {
        children: icon
      }), _jsx(TextWrapper, {
        secondaryTypographyProps: {
          color: 'text.primary',
          variant: 'body1'
        },
        primaryTypographyProps: {
          color: 'text.secondary',
          variant: 'body2'
        },
        secondary: children,
        children: label
      }), actionNode]
    })
  });
};
export default UserAttributeLayout;