import ContentLoader from 'presentation/components/common/loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddressHelper } from 'utils/address';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import { Wrapper } from './controls';
import { locationDetectorCitySelector } from './store/selectors';
import { locationDetectorCityFetch, locationDetectorClear } from './store/slice';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const LocationDetector = _ref => {
  let {
    children
  } = _ref;
  const dispatch = useDispatch();
  const {
    settings,
    setCity
  } = useUserLocalSettings();

  /*const [cityChangeDialogVisible, setCityChangeDialogVisible] = useState<boolean>(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);*/

  const {
    city: localCity
  } = settings;
  const {
    data: city,
    isFetching
  } = useSelector(locationDetectorCitySelector);

  /*const openCityChangeDialog = () => {
    setCityChangeDialogVisible(true);
  };
   const closeCityChangeDialog = () => {
    setCityChangeDialogVisible(false);
  };
   const closeCityConfirmDialog = () => {
    setConfirmDialogVisible(false);
  };
   const openCityConfirmDialog = () => {
    setConfirmDialogVisible(true);
  };
   const onChangeCity = useCallback(
    (newCity: Address) => {
      setCity(newCity.id, new AddressHelper(newCity).getLocalitySimpleName());
    },
    [setCity]
  );*/

  useEffect(() => {
    if (!localCity) {
      const promise = dispatch(locationDetectorCityFetch());
      return () => {
        dispatch(locationDetectorClear());
        promise?.abort();
      };
    }
  }, [dispatch, localCity]);
  useEffect(() => {
    if (city) {
      setCity(city.id, new AddressHelper(city).getLocalitySimpleName());
      /*openCityConfirmDialog();*/
    }
  }, [city, setCity]);
  return _jsxs(_Fragment, {
    children: [isFetching && _jsx(Wrapper, {
      children: _jsx(ContentLoader, {})
    }), settings.city && children]
  });
};
export default LocationDetector;