import { useActivateUserOfferMutation, useGetUserOfferActivationsQuery, useGetUserOfferAvailabilityQuery } from 'data/api/user';
import { businessErrorCode } from 'data/network/constants';
import ErrorHandler from 'data/network/errorHandler';
import { ECorpOfferPromotionType, EOfferActivateError, EOfferActivationStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import { useCallback, useEffect, useState } from 'react';
import Notifier from '../../../../system/notifier';
import useCurrentUserBalanceByType from '../../user/current/useBalanceByType';
import { getOfferLastActivation } from '../utils';
const useOfferActivations = props => {
  const {
    offerId,
    offerPrice,
    balanceType,
    offerRefetch,
    isActivationAllowed = true
  } = props;
  const [activationError, setActivationError] = useState(null);
  const balance = useCurrentUserBalanceByType(balanceType ?? null);
  const {
    data: activations = [],
    error: activationsError,
    isFetching: activationsIsFetching,
    refetch: activationsRefetch
  } = useGetUserOfferActivationsQuery({
    id: offerId
  }, {
    refetchOnMountOrArgChange: true,
    skip: !isActivationAllowed
  });
  const {
    data: unavailabilityReasons = [],
    error: availabilityError,
    isFetching: availabilityIsFetching,
    refetch: availabilityRefetch
  } = useGetUserOfferAvailabilityQuery({
    offerId
  }, {
    refetchOnMountOrArgChange: true,
    skip: !isActivationAllowed
  });
  const [activateOfferInternal, activateOfferResult] = useActivateUserOfferMutation();
  const {
    isLoading: activationIsFetching,
    data: lastReceivedActivation = null
  } = activateOfferResult;
  const lastActivation = getOfferLastActivation(activations) ?? null;
  const isLastActivationPending = lastActivation?.status === EOfferActivationStatus.Pending;
  const parseActivationError = useCallback(error => {
    const type = error.code;
    const message = error.message;
    switch (type) {
      case EOfferActivateError.PromotionFreshOut:
        ErrorHandler.handleBusinessError(error);
        offerRefetch();
        availabilityRefetch();
        break;
      default:
        setActivationError({
          type,
          message
        });
    }
  }, [availabilityRefetch, offerRefetch]);
  const parseActivationErrorResponse = useCallback(response => {
    switch (response.status) {
      case businessErrorCode:
        if (!response.data.code) {
          setActivationError({
            type: null
          });
          ErrorHandler.handleHttpError(response);
        } else {
          parseActivationError(response.data);
        }
        return;
      case 500:
        setActivationError({
          type: null
        });
        return;
      default:
        {
          ErrorHandler.handleHttpError(response);
        }
    }
  }, [parseActivationError]);
  const activateOffer = useCallback(async offer => {
    let callActivate = false;
    switch (offer.promotionType) {
      case ECorpOfferPromotionType.ExternalCertificate:
        callActivate = false;
        break;
      case ECorpOfferPromotionType.Certificate:
        callActivate = true;
        break;
      case ETradeOfferPromotionType.AccessCode:
      case ETradeOfferPromotionType.Promocode:
      case ETradeOfferPromotionType.Voucher:
        callActivate = true;
        break;
      case ETradeOfferPromotionType.PublicPromocode:
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.ReferralLink:
        callActivate = true;
        break;
      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
        callActivate = true;
        break;
    }
    let result = null;
    if (callActivate) {
      setActivationError(null);
      try {
        result = await activateOfferInternal({
          id: offer.id
        }).unwrap();
        offerRefetch();
        activationsRefetch();
      } catch (e) {
        parseActivationErrorResponse(e);
      }
    }
    return result;
  }, [activateOfferInternal, offerRefetch, activationsRefetch, parseActivationErrorResponse]);
  const activationsReload = useCallback(() => {
    activationsRefetch();
    availabilityRefetch();
  }, [activationsRefetch, availabilityRefetch]);
  const isLoadActivationsFetching = activationsIsFetching || availabilityIsFetching;
  const isLoadActivationsFailed = !!activationsError || !!availabilityError;
  useEffect(() => {
    if (isLoadActivationsFailed) {
      Notifier.getInstance().addError('Ошибка при получении. Попробуйте ещё раз позже');
    }
  }, [isLoadActivationsFailed]);
  const isActivationAvailable = !unavailabilityReasons.some(reason => reason === EOfferActivateError.InappropriateTargeting) && activationError?.type !== EOfferActivateError.InappropriateTargeting;
  const isReactivationSupported = !unavailabilityReasons.some(reason => reason === EOfferActivateError.OfferActivationAlreadyExist);
  const isUserBalanceNotEnough = !balance?.balance || balance?.balance < (offerPrice ?? 0);
  return {
    isLoadActivationsFetching,
    isLoadActivationsFailed,
    isReactivationSupported,
    activationsReload,
    isActivationAvailable,
    isUserBalanceNotEnough,
    activateOffer,
    activationError,
    activationIsFetching,
    activations,
    isLastActivationPending,
    lastActivation,
    lastReceivedActivation
  };
};
export default useOfferActivations;