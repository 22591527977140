import OfferPartnerInfo from 'presentation/features/offer/components/partnerInfo';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { getPartnerDeskDetailsRoute } from '../../../../partnerDesk/entry';
import { EPartnerDeskDetailsTab } from '../../../../partnerDesk/details/utils';
import { useHistory } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferPartnerInfoAdapter = _ref => {
  let {
    id
  } = _ref;
  const history = useHistory();
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  const onPartnerClick = () => {
    if (bookingOffer) {
      history.push(getPartnerDeskDetailsRoute({
        id: bookingOffer.partner.id,
        tab: EPartnerDeskDetailsTab.BookingOffers
      }));
    }
  };
  if (!bookingOffer?.partner) {
    return null;
  }
  return _jsx(OfferPartnerInfo, {
    partner: bookingOffer.partner,
    onClick: onPartnerClick
  });
};
export default BookingOfferPartnerInfoAdapter;