import { Typography } from '@mui/material';
import AddressCitySingleSelector from 'presentation/components/common/address/singleSelector/city';
import Splitter from 'presentation/components/common/splitter';
import useUserLocalSettings from 'presentation/hooks/useUserLocalSettings';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPLink } from 'presentation/theme/ui-kit/link';
import { AddressHelper } from 'utils/address';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const OnboardingSelectLocalityAdapter = _ref => {
  let {
    onNext
  } = _ref;
  const {
    settings: {
      city
    },
    setCity
  } = useUserLocalSettings();
  const onChangeCity = newCity => {
    if (newCity) {
      setCity(newCity.id, new AddressHelper(newCity).getLocalitySimpleName());
      onNext();
    }
  };
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      children: [_jsxs(Typography, {
        variant: "body2",
        children: ["\u041C\u044B \u043F\u043E\u043F\u044B\u0442\u0430\u043B\u0438\u0441\u044C \u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0438\u0442\u044C \u0432\u0430\u0448\u0435 \u043C\u0435\u0441\u0442\u043E\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u0435.", _jsxs(Typography, {
          variant: "inherit",
          children: ["\u042D\u0442\u043E ", _jsx("b", {
            children: city?.name
          }), "?"]
        })]
      }), _jsx(Splitter, {
        size: 1
      }), _jsx(MPButton, {
        fullWidth: true,
        size: "small",
        color: "brand",
        variant: "contained",
        onClick: onNext,
        children: "\u0414\u0430"
      }), _jsx(Splitter, {
        size: 1
      })]
    }), _jsxs("div", {
      children: [_jsxs("div", {
        children: [_jsx(Typography, {
          component: MPLink,
          children: "\u041C\u043E\u0441\u043A\u0432\u0430"
        }), _jsx(Typography, {
          component: MPLink,
          children: "\u0421\u0430\u043D\u043A\u0442-\u041F\u0435\u0442\u0435\u0440\u0431\u0443\u0440\u0433"
        })]
      }), _jsx(Splitter, {
        size: 2
      }), _jsx(AddressCitySingleSelector, {
        value: null,
        noOptionsText: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435",
        label: "\u0414\u0440\u0443\u0433\u043E\u0439 \u0433\u043E\u0440\u043E\u0434",
        getOptionLabel: address => new AddressHelper(address).getLocalitySimpleName(),
        onChange: onChangeCity
      })]
    })]
  });
};