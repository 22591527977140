import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmButtons from '../../../../theme/ui-kit/dialog/buttons';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserCorpLinkAcceptDialog = props => {
  const {
    open,
    onAccept,
    onClose
  } = props;
  return _jsx(MPConfirmDialog, {
    title: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C, \u0447\u0442\u043E \u0432\u044B \u044F\u0432\u043B\u044F\u0435\u0442\u0435\u0441\u044C \u0440\u0430\u0431\u043E\u0442\u043D\u0438\u043A\u043E\u043C \xAB\u041E\u0410\u041E \u0420\u0416\u0414\xBB?",
    open: open,
    maxWidth: "xs",
    buttons: _jsxs(ConfirmButtons, {
      children: [_jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        onClick: onAccept,
        children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C"
      }), _jsx(MPButton, {
        fullWidth: true,
        variant: "text",
        color: "brand",
        size: "large",
        onClick: onClose,
        children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C"
      })]
    }),
    children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C \u0431\u0443\u0434\u0435\u0442 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D \u0434\u0430\u043D\u043D\u044B\u043C\u0438 \u0438\u0437 \u0421\u0435\u0440\u0432\u0438\u0441\u043D\u043E\u0433\u043E \u043F\u043E\u0440\u0442\u0430\u043B\u0430 \u043F\u043E\u0441\u043B\u0435 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F"
  });
};
export default UserCorpLinkAcceptDialog;