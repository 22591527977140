import base64url from 'base64url';
import { useEffect } from 'react';
import { EMultiSelectorValueType, ETargetType } from '../../../../../domain/model/enums';
import useUserLocalSettings from '../../../../hooks/useUserLocalSettings';
import { useAuth } from '../../../auth/provider/useAuth';
import { applyInterceptor, clearInterceptor } from './useClearGuestTargetInterceptor';

// для гостевого режима
// на новый заголовок "x-target-value", который автоматически добавляется во все request
export const useGuestTarget = () => {
  const {
    isAuthenticated
  } = useAuth();
  const {
    settings
  } = useUserLocalSettings();
  const city = settings?.city;

  // обновляем lss
  useEffect(() => {
    if (!isAuthenticated && city?.fiasId) {
      const target = {
        targetType: ETargetType.Geo,
        targetLocalities: {
          in: [{
            id: city.fiasId
          }],
          select: EMultiSelectorValueType.In
        },
        targetExternalUsers: true
      };
      const stringifiedTarget = JSON.stringify(target);
      const encodedTargetHeader = base64url.encode(stringifiedTarget);
      applyInterceptor(encodedTargetHeader);
    }
    return () => {
      clearInterceptor();
    };
  }, [isAuthenticated, city?.fiasId]);
};