import { UserFinanceBalancesAdapter, UserFinanceHistoryAdapter, UserFinancesLoaderAdapter } from './adapters';
import { UserFinancesLayout } from './layout';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = UserFinancesLayout;
const UserFinancesContainer = () => {
  return _jsx(Layout, {
    header: "\u041C\u043E\u0439 \u0431\u0430\u043B\u0430\u043D\u0441",
    loader: _jsx(UserFinancesLoaderAdapter, {}),
    balances: _jsx(UserFinanceBalancesAdapter, {}),
    history: _jsx(UserFinanceHistoryAdapter, {})
  });
};
export default UserFinancesContainer;