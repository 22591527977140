import { useSelector } from 'react-redux';
import { assistantIsScenarioActiveSelector } from '../store/selectors';
const AssistantScenarioViewer = _ref => {
  let {
    type,
    children
  } = _ref;
  const isActive = useSelector(assistantIsScenarioActiveSelector(type));
  return isActive && children;
};
export default AssistantScenarioViewer;