import { EPartnerType } from 'domain/model/enums';
import { LogoImageView } from 'presentation/components/common/images/cover';
import { CardCaption } from 'presentation/features/offer/components/listItem/controls';
import { innerPartnerLogoStub } from 'presentation/features/partnerDesk/utils';
import { AddressHelper } from '../../../../../../utils/address';
import { LogoWrapper } from '../../../components/listItem';
import { InfoWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingListItemInfo = props => {
  const {
    address,
    partner: {
      logo,
      type
    }
  } = props;
  const logoPath = !logo && type === EPartnerType.InnerPartner ? innerPartnerLogoStub : logo?.path;
  return _jsxs(InfoWrapper, {
    children: [address && _jsx(CardCaption, {
      variant: "body2",
      color: "textSecondary",
      children: new AddressHelper(address).getLocalitySimpleName()
    }), logoPath && _jsx(LogoWrapper, {
      children: _jsx(LogoImageView, {
        id: logoPath
      })
    })]
  });
};
export default BookingListItemInfo;