import { useCallback, useState } from 'react';
import CorpOfferActivateDialog from '../../../notification/dialogs/corpOfferActivate';
import { ENotificationMessageType } from '../../../notification/types';
import useNotificationSocket from '../../../notification/useNotificationSocket';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const CorpOffersNotificationsReceiver = () => {
  const [corpOfferActivateDialogState, setCorpOfferActivateDialogState] = useState(null);
  const onMessageReceive = useCallback(msg => {
    switch (msg.type) {
      case ENotificationMessageType.OfferActivationChangedMessage:
        setCorpOfferActivateDialogState({
          title: msg.title,
          description: msg.description
        });
        return;
      default:
        return;
    }
  }, []);
  useNotificationSocket({
    onMessageReceive
  });
  return _jsx(_Fragment, {
    children: _jsx(CorpOfferActivateDialog, {
      open: corpOfferActivateDialogState !== null,
      title: corpOfferActivateDialogState?.title ?? '',
      description: corpOfferActivateDialogState?.description ?? '',
      onClose: () => setCorpOfferActivateDialogState(null)
    })
  });
};
export default CorpOffersNotificationsReceiver;