import AssistantCommonLayout from '../../layouts/common';
import { Rating } from '@mui/material';
import Splitter from '../../../../components/common/splitter';

/**
 * сценарий оценки витрины
 * рендерится как умолчальный
 */
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AssistantScenarioRatingContainer = () => {
  return _jsxs(AssistantCommonLayout, {
    children: [_jsx("div", {
      children: "\u041E\u0446\u0435\u043D\u0438\u0442\u0435 \u0412\u0438\u0442\u0440\u0438\u043D\u0443"
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(Rating, {
      size: "large",
      value: null
    })]
  });
};
export default AssistantScenarioRatingContainer;