import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import UserFinancesContainer from '../../features/user/details/finances/container';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserFinancesScreen = () => {
  return _jsx(RequiredGuidInLocationContainer, {
    children: () => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(UserFinancesContainer, {})
    })
  });
};
export default UserFinancesScreen;