import HistoryExtensionContainer from 'presentation/features/general/historyExtension/container';
import Notifier from 'presentation/features/general/notifier/container';
import { WebAnalyticsInitializer } from 'presentation/features/webAnalytics';
import { GlobalPreviewModeContainer } from 'presentation/screen/cms/globalPreviewMode/container';
import { GuestTargetContainer } from 'presentation/features/user/guestTarget/container';
import AssistantWidgetContainer from 'presentation/features/assistant/widget/container';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AppInfrastructure = () => {
  return _jsxs(_Fragment, {
    children: [_jsx(WebAnalyticsInitializer, {}), _jsx(GlobalPreviewModeContainer, {}), _jsx(GuestTargetContainer, {}), _jsx(Notifier, {}), _jsx(HistoryExtensionContainer, {}), _jsx(AssistantWidgetContainer, {})]
  });
};
export default AppInfrastructure;