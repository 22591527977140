import { Typography } from '@mui/material';
import moment from 'moment';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import ConfirmButtons from '../../../../../../theme/ui-kit/dialog/buttons';
import { pluralize } from '../../../../../../utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferGetNewCertDialog = _ref => {
  let {
    open,
    price,
    offerEndDate,
    activationExpiryDays,
    onGetCertificate,
    onClose
  } = _ref;
  const activationExpiryDate = moment(offerEndDate).endOf('day').add(activationExpiryDays, 'day');
  return _jsxs(MPConfirmDialog, {
    open: open,
    maxWidth: "xs",
    title: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0435\u0449\u0451 \u043E\u0434\u0438\u043D \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442?",
    onClose: onClose,
    buttons: _jsxs(ConfirmButtons, {
      children: [_jsx(MPButton, {
        color: "brand",
        size: "large",
        fullWidth: true,
        onClick: onGetCertificate,
        children: "\u0414\u0430, \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0435\u0449\u0435 \u043E\u0434\u0438\u043D"
      }), _jsx(MPButton, {
        color: "brand",
        size: "large",
        fullWidth: true,
        variant: "text",
        onClick: onClose,
        children: "\u041D\u0435\u0442"
      })]
    }),
    children: [_jsxs(Typography, {
      children: ["\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u044D\u0442\u043E\u0433\u043E \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u0443\u0436\u0435 \u0435\u0441\u0442\u044C \u0432 \u0440\u0430\u0437\u0434\u0435\u043B\u0435 \u041C\u043E\u0438 \u043F\u0440\u0438\u0432\u0438\u043B\u0435\u0433\u0438\u0438. \u0425\u043E\u0442\u0438\u0442\u0435 \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043D\u043E\u0432\u044B\u0439 \u0437\u0430 ", price, ' ', pluralize(price, ['балл', 'балла', 'баллов']), "?"]
    }), _jsx(Typography, {
      fontWeight: "bold",
      children: "\u0412\u043D\u0438\u043C\u0430\u043D\u0438\u0435!"
    }), _jsxs(Typography, {
      children: ["\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D \u0434\u043E ", moment(activationExpiryDate).format(EDateFormat.Human), " \u0433\u043E\u0434\u0430."]
    })]
  });
};
export default CorpOfferGetNewCertDialog;