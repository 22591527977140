import { SkirtWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ClaimInfo = _ref => {
  let {
    children,
    ...other
  } = _ref;
  return _jsx(SkirtWrapper, {
    ...other,
    children: children
  });
};
export default ClaimInfo;